"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TIME_HOURS_MINUTES = exports.POST_CODE = exports.LETTERS_NUMBERS_SYMBOLS = exports.WEAK_EMAIL = exports.EMAIL = exports.PHONE = exports.LATIN_LETTERS_NUMBERS_SYMBOLS = void 0;
exports.LATIN_LETTERS_NUMBERS_SYMBOLS = /^(\p{L}|\d| |-|–|—|‒|−|‐)*$/u;
exports.PHONE = /^[\d+]+$/;
exports.EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.WEAK_EMAIL = /^[^@]*(?:@[^@]*)?$/;
exports.LETTERS_NUMBERS_SYMBOLS = /^[A-Za-z0-9-!$%^&*()_+|~=`{}[\]:";'<>?,.#@№]+$/;
exports.POST_CODE = /^\d{1,5}$/;
exports.TIME_HOURS_MINUTES = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
