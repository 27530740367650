"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LessonElementVideoFormSchema = exports.LessonElementHomeWorkFormSchema = exports.LessonElementPageFormSchema = exports.LessonElementsCreateFormSchema = exports.LessonElementsUpdateOrderSchema = exports.LessonElementsFindResponseSchema = exports.LessonElementsFindParamsSchema = exports.LessonElementsFindOrderParams = exports.LessonElementsFindFilterParamsSchema = exports.LessonElementsSchemaResponse = exports.LessonElementsParamsSchema = exports.LessonElementsCreateUpdateSchema = exports.LessonElementsSchema = exports.BaseLessonElementCreateUpdateSchema = exports.LessonHomeworkType = exports.LessonVideoType = exports.LessonPageType = exports.BaseLessonElementSchema = exports.LessonElementName = exports.LessonCategoriesFindResponseSchema = exports.LessonCategoriesFindParamsSchema = exports.LessonCategoriesFindOrderParams = exports.LessonCategoriesFindFilterParamsSchema = exports.LessonCategoriesSchemaResponse = exports.LessonCategoriesParamsSchema = exports.LessonCategoriesCreateUpdateSchema = exports.LessonCategoriesSchema = exports.LessonCategoryName = exports.LessonUpdateStatusSchema = exports.LessonFindResponseSchema = exports.LessonFindParamsSchema = exports.LessonFindExcludeParamsSchema = exports.LessonFindOrderParams = exports.LessonFindFilterParamsSchema = exports.LessonsDetachSchema = exports.LessonsAttachSchema = exports.LessonSingleAttachSchema = exports.LessonParamsSchema = exports.LessonUpdateSchema = exports.LessonSchemaResponse = exports.LessonCreateFormSchema = exports.LessonCreateSchema = exports.LessonSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
const common_1 = require("../../common");
const course_1 = require("../course");
const types_1 = require("./types");
const utils_1 = require("../../utils");
const constants_1 = require("../../constants");
const types_2 = require("../../types");
exports.LessonSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    course_id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String({ minLength: 2, maxLength: 500 }),
    description: (0, common_1.Nullable)(typebox_1.Type.String({ maxLength: constants_1.POSTGRES_MAX_VARCHAR_SIZE })),
    status: typebox_1.Type.Enum(course_1.COURSE_STATUSES, { default: course_1.COURSE_STATUSES.ACTIVE }),
    image_url: (0, common_1.Nullable)(typebox_1.Type.String()),
    accessible_from: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date-time' })),
    accessible_to: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date-time' })),
    view_type: typebox_1.Type.Enum(types_1.ViewType, { default: types_1.ViewType.Pages }),
    done_criteria: typebox_1.Type.Enum(types_1.LessonDoneCriteria),
    category_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
}, { additionalProperties: false });
exports.LessonCreateSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.LessonSchema, ['id', 'status']),
    typebox_1.Type.Object({
        topic_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
        order: typebox_1.Type.Optional(typebox_1.Type.Integer({ minimum: 1 })),
    }),
], {
    additionalProperties: false,
});
exports.LessonCreateFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Pick(exports.LessonCreateSchema, ['name']),
    typebox_1.Type.Object({
        category: (0, common_1.Nullable)(common_1.FormLookupOption),
    }),
]);
exports.LessonSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.LessonSchema], {
    additionalProperties: false,
});
exports.LessonUpdateSchema = typebox_1.Type.Omit(exports.LessonSchema, ['id', 'status', 'course_id'], {
    additionalProperties: false,
});
exports.LessonParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.LessonSingleAttachSchema = typebox_1.Type.Object({
    lesson_id: typebox_1.Type.Integer(),
    order: typebox_1.Type.Integer({ minimum: 1 }),
});
exports.LessonsAttachSchema = typebox_1.Type.Object({
    topic_id: typebox_1.Type.Integer(),
    lessons: typebox_1.Type.Array(exports.LessonSingleAttachSchema),
});
exports.LessonsDetachSchema = typebox_1.Type.Object({
    topic_id: typebox_1.Type.Integer(),
    lesson_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.LessonFindFilterParamsSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.LessonFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.LessonSchemaResponse, [
    'status',
    'created_at',
    'updated_at',
]));
exports.LessonFindExcludeParamsSchema = typebox_1.Type.Object({
    course_version_id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
});
exports.LessonFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.LessonFindFilterParamsSchema,
    order: exports.LessonFindOrderParams,
    exclude: exports.LessonFindExcludeParamsSchema,
});
exports.LessonFindResponseSchema = (0, responses_1.PaginatedList)(exports.LessonSchemaResponse);
exports.LessonUpdateStatusSchema = typebox_1.Type.Object({
    status: typebox_1.Type.Enum(course_1.COURSE_STATUSES),
}, { additionalProperties: false });
exports.LessonCategoryName = typebox_1.Type.String({ minLength: 1, maxLength: 255 });
exports.LessonCategoriesSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: exports.LessonCategoryName,
}, { additionalProperties: false });
exports.LessonCategoriesCreateUpdateSchema = typebox_1.Type.Pick(exports.LessonCategoriesSchema, ['name']);
exports.LessonCategoriesParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.LessonCategoriesSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.LessonCategoriesSchema], {
    additionalProperties: false,
});
exports.LessonCategoriesFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.LessonCategoriesFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.LessonCategoriesSchemaResponse, ['name']));
exports.LessonCategoriesFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.LessonCategoriesFindFilterParamsSchema,
    order: exports.LessonCategoriesFindOrderParams,
});
exports.LessonCategoriesFindResponseSchema = (0, responses_1.PaginatedList)(exports.LessonCategoriesSchema);
exports.LessonElementName = typebox_1.Type.String({ minLength: 1, maxLength: 200 });
exports.BaseLessonElementSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: exports.LessonElementName,
    order: typebox_1.Type.Integer({ minimum: 1 }),
    required: typebox_1.Type.Boolean({ default: false }),
    lesson_id: typebox_1.Type.Integer(),
});
exports.LessonPageType = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.LessonElementType.Page),
    content: typebox_1.Type.Object({
        description: (0, common_1.Nullable)(typebox_1.Type.String({ maxLength: constants_1.POSTGRES_MAX_VARCHAR_SIZE })),
    }, { additionalProperties: false }),
});
exports.LessonVideoType = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.LessonElementType.Video),
    content: typebox_1.Type.Object({
        url: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'uri' })),
    }, { additionalProperties: false }),
});
exports.LessonHomeworkType = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.LessonElementType.Homework),
    content: typebox_1.Type.Object({
        description: (0, common_1.Nullable)(typebox_1.Type.String({ maxLength: constants_1.POSTGRES_MAX_VARCHAR_SIZE })),
        maxFiles: typebox_1.Type.Integer({ minimum: 0, maximum: constants_1.DEFAULT_MAXIMUM_FILE_COUNT, default: 5 }),
        maxFilesSize: (0, common_1.FileSize)(constants_1.DEFAULT_FILE_SIZE_MB),
    }, { additionalProperties: false }),
});
exports.BaseLessonElementCreateUpdateSchema = typebox_1.Type.Omit(exports.BaseLessonElementSchema, ['id', 'lesson_id'], {
    additionalProperties: false,
});
exports.LessonElementsSchema = (0, utils_1.OneOf)([
    typebox_1.Type.Composite([exports.BaseLessonElementSchema, exports.LessonPageType]),
    typebox_1.Type.Composite([exports.BaseLessonElementSchema, exports.LessonVideoType]),
    typebox_1.Type.Composite([exports.BaseLessonElementSchema, exports.LessonHomeworkType]),
], {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
exports.LessonElementsCreateUpdateSchema = (0, utils_1.OneOf)([
    typebox_1.Type.Composite([exports.BaseLessonElementCreateUpdateSchema, exports.LessonPageType], { additionalProperties: false }),
    typebox_1.Type.Composite([exports.BaseLessonElementCreateUpdateSchema, exports.LessonVideoType], { additionalProperties: false }),
    typebox_1.Type.Composite([exports.BaseLessonElementCreateUpdateSchema, exports.LessonHomeworkType], { additionalProperties: false }),
], {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
exports.LessonElementsParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    elementId: typebox_1.Type.Integer(),
});
exports.LessonElementsSchemaResponse = exports.LessonElementsSchema;
exports.LessonElementsFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.LessonElementsFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.BaseLessonElementSchema, ['name']));
exports.LessonElementsFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.LessonElementsFindFilterParamsSchema,
    order: exports.LessonElementsFindOrderParams,
});
exports.LessonElementsFindResponseSchema = typebox_1.Type.Object({
    items: typebox_1.Type.Array(exports.LessonElementsSchema),
});
exports.LessonElementsUpdateOrderSchema = typebox_1.Type.Object({
    items: typebox_1.Type.Array(typebox_1.Type.Object({
        id: typebox_1.Type.Integer(),
        order: typebox_1.Type.Integer({ minimum: 1 }),
    }, { additionalProperties: false })),
}, { additionalProperties: false });
// TODO: check on review
exports.LessonElementsCreateFormSchema = typebox_1.Type.Object({
    name: typebox_1.Type.String({ minLength: 2, maxLength: 200 }),
});
exports.LessonElementPageFormSchema = typebox_1.Type.Object({
    name: typebox_1.Type.String({ minLength: 2, maxLength: 200 }),
    description: (0, types_2.Wysiwyg)({ minLength: 1 }),
});
exports.LessonElementHomeWorkFormSchema = typebox_1.Type.Object({
    name: typebox_1.Type.String({ minLength: 2, maxLength: 200 }),
    filesLimit: common_1.FormLookupOption,
    description: (0, types_2.Wysiwyg)({ minLength: 1 }),
});
exports.LessonElementVideoFormSchema = typebox_1.Type.Object({
    name: typebox_1.Type.String({ minLength: 2, maxLength: 200 }),
    url: typebox_1.Type.String({ format: 'uri' }),
});
