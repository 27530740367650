"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageGroupFindResponseSchema = exports.PackageGroupFindParamsSchema = exports.PackageGroupOrderParamsSchema = exports.PackageGroupFilterParams = exports.PackageGroupResponse = exports.PackageGroupParamsSchema = exports.PackageGroupCreateUpdatePayload = exports.PackageGroupSchema = exports.PackageGroupBaseSchema = exports.PackageGroupNameSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
exports.PackageGroupNameSchema = typebox_1.Type.String({ minLength: 1, maxLength: 100 });
exports.PackageGroupBaseSchema = typebox_1.Type.Object({
    name: exports.PackageGroupNameSchema,
});
exports.PackageGroupSchema = typebox_1.Type.Composite([exports.PackageGroupBaseSchema, baseEntity_1.BaseEntitySchema]);
exports.PackageGroupCreateUpdatePayload = exports.PackageGroupBaseSchema;
exports.PackageGroupParamsSchema = typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['id']);
exports.PackageGroupResponse = typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['id']);
exports.PackageGroupFilterParams = typebox_1.Type.Partial(exports.PackageGroupBaseSchema);
exports.PackageGroupOrderParamsSchema = typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['created_at']);
exports.PackageGroupFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.PackageGroupFilterParams,
    order: exports.PackageGroupOrderParamsSchema,
});
exports.PackageGroupFindResponseSchema = (0, responses_1.PaginatedList)(exports.PackageGroupSchema);
