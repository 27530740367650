"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestAttemptStatus = exports.TestActionName = exports.TestType = void 0;
var TestType;
(function (TestType) {
    TestType["ASSESSMENT"] = "ASSESSMENT";
    TestType["SELF_STUDY"] = "SELF_STUDY";
    TestType["TOPIC_TEST"] = "TOPIC_TEST";
})(TestType || (exports.TestType = TestType = {}));
var TestActionName;
(function (TestActionName) {
    TestActionName["Attach"] = "Attach";
    TestActionName["Detach"] = "Detach";
})(TestActionName || (exports.TestActionName = TestActionName = {}));
var TestAttemptStatus;
(function (TestAttemptStatus) {
    TestAttemptStatus["InProgress"] = "InProgress";
    TestAttemptStatus["Done"] = "Done";
})(TestAttemptStatus || (exports.TestAttemptStatus = TestAttemptStatus = {}));
