import { UserCircleIcon } from '@heroicons/react/24/outline'
import ChevronUpIcon from '@heroicons/react/24/outline/ChevronUpIcon'
import { useAsideContext } from '@repo/ui/PrivateLayout'
import clsx from 'clsx'
import Popper from '@repo/ui/Popper'
import { useRef, useState } from 'react'
import UserCardPopover from 'src/components/Layouts/PrivateLayout/components/UserCardPopover'
import { s3FileUrlBuilder } from 'src/shared/utils/s3FileUrlBuilder.ts'
import { observer } from 'mobx-react'
import { Button } from '@repo/ui/Buttons'
import { useService } from 'src/shared/hooks/di'
import { MeStore } from 'src/modules/UsersModule/User/stores/MeStore'

const UserCard = () => {
  const { me } = useService(MeStore)
  const isAsideOpen = useAsideContext()
  const [isOpen, setIsOpen] = useState(false)
  const dropdownSourceRef = useRef<SVGSVGElement | null>(null)
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Button className='flex w-full flex-col px-2.5' onClick={handleToggle} variant='text' theme='secondary'>
        {me?.avatar_url_path ? (
          <img
            ref={dropdownSourceRef as React.LegacyRef<HTMLImageElement>}
            src={s3FileUrlBuilder(me?.avatar_url_path)}
            alt='avatar'
            className='size-10 rounded-full'
          />
        ) : (
          <UserCircleIcon
            ref={dropdownSourceRef as React.LegacyRef<SVGSVGElement>}
            className={clsx(
              {
                'self-center': !isAsideOpen,
              },
              'size-8',
            )}
          />
        )}
        {isAsideOpen && (
          <div className='flex w-full items-end justify-between'>
            <div className='flex flex-wrap text-sm'>
              <span className='break-all font-semibold'>{me?.first_name}</span>&nbsp;
              <span className='break-all font-semibold'>{me?.last_name}</span>
            </div>
            <ChevronUpIcon
              className={clsx(
                {
                  'rotate-180': !isOpen,
                },
                'size-5',
              )}
            />
          </div>
        )}
        {isAsideOpen && <div className='text-sm text-gray-500'>{me?.system_role}</div>}
      </Button>
      <Popper
        isOpen={isOpen}
        onClose={handleClose}
        placement='top-end'
        sourceRef={dropdownSourceRef}
        className='z-50 rounded-md border bg-white'
      >
        <UserCardPopover />
      </Popper>
    </>
  )
}

export default observer(UserCard)
