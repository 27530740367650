import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const WorkspacePage = lazy(() => import('src/pages/WorkspacePages/StaffManagement.tsx'))
const TeacherProfilePage = lazy(() => import('src/pages/WorkspacePages/TeacherProfile.tsx'))

export default [
  {
    path: ROUTES.WORKSPACE.STAFF_MANAGEMENT,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <WorkspacePage />
      </Suspense>
    ),
    isScrollable: false,
  },
  {
    path: ROUTES.WORKSPACE.STAFF_MANAGEMENT_PROFILE,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <TeacherProfilePage />
      </Suspense>
    ),
    isScrollable: false,
  },
]
