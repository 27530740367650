"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaffDeleteSchema = exports.StaffSubordinatesResponseListSchema = exports.StaffSubordinatesResponseSchema = exports.StaffSubordinatesFindParamsSchema = exports.StaffSubordinatesOrderParamsSchema = exports.StaffSubordinatesFilterParamsSchema = exports.StaffSubordinatesCreateSchema = exports.StaffTeacherRelationsSchema = exports.StaffTeacherRelationsBaseSchema = exports.StaffWorkingHoursListResponseSchema = exports.StaffTeacherWorkingHoursSingleTeacherFindParamsSchema = exports.StaffWorkingHoursSingleTeacherOrderParams = exports.StaffWorkingHoursSingleTeacherParamsSchema = exports.StaffTeacherWorkingHoursFindParamsSchema = exports.StaffTeacherWorkingHoursOrderParamsSchema = exports.StaffTeacherWorkingHoursFilterParamsSchema = exports.StaffTeacherWorkingHoursFormSchema = exports.StaffTeacherWorkingHoursSchema = exports.StaffWorkingHoursParamsSchema = exports.StaffWorkingHoursSchema = exports.StaffWorkingHoursDBSchema = exports.ParsedISOStringSchema = exports.ISOStringSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const common_1 = require("../../common");
const user_1 = require("../user");
const responses_1 = require("../../responses");
const calendar_plan_1 = require("../calendar-plan");
exports.ISOStringSchema = typebox_1.Type.String({ format: 'date-time' });
exports.ParsedISOStringSchema = typebox_1.Type.Object({
    year: typebox_1.Type.Integer(),
    month: typebox_1.Type.Integer(),
    day: typebox_1.Type.Integer(),
    hour: typebox_1.Type.Integer(),
    minute: typebox_1.Type.Integer(),
    second: typebox_1.Type.Integer(),
});
exports.StaffWorkingHoursDBSchema = typebox_1.Type.Object({
    id: common_1.NaturalIntegerSchema,
    teacher_id: common_1.NaturalIntegerSchema,
    start_time: typebox_1.Type.String({ format: 'date-time' }),
    end_time: typebox_1.Type.String({ format: 'date-time' }),
});
exports.StaffWorkingHoursSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    teacher_id: typebox_1.Type.Number(),
    start_time: typebox_1.Type.String({ format: 'date-time' }),
    end_time: typebox_1.Type.String({ format: 'date-time' }),
});
exports.StaffWorkingHoursParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.StaffTeacherWorkingHoursSchema = typebox_1.Type.Object({
    user_id: typebox_1.Type.Integer(),
    rrule: typebox_1.Type.String(),
    start_time: typebox_1.Type.String({ format: 'date-time' }),
    end_time: typebox_1.Type.String({ format: 'date-time' }),
    // date: Type.String({ format: 'date-time' }),
});
exports.StaffTeacherWorkingHoursFormSchema = typebox_1.Type.Object({
    start_time: typebox_1.Type.Optional(typebox_1.Type.String({ pattern: '^([01]\\d|2[0-3]):([0-5]\\d)$', errorMessage: 'wrongTimeFormat' })),
    end_time: typebox_1.Type.Optional(typebox_1.Type.String({ pattern: '^([01]\\d|2[0-3]):([0-5]\\d)$', errorMessage: 'wrongTimeFormat' })),
    freq: (0, common_1.Nullable)(common_1.FormLookupOption),
    endDate: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date' })),
    endAfterTimes: (0, common_1.Nullable)(typebox_1.Type.Number()),
    interval: typebox_1.Type.Optional(typebox_1.Type.Number({ minimum: calendar_plan_1.INTERVAL_MIN_VALUE })),
    rrule: typebox_1.Type.String(),
    event_date: typebox_1.Type.String({ format: 'date-time' }),
});
exports.StaffTeacherWorkingHoursFilterParamsSchema = typebox_1.Type.Object({
    teacher_id: typebox_1.Type.Integer(),
    start_date: typebox_1.Type.String({ format: 'date-time' }),
    end_date: typebox_1.Type.String({ format: 'date-time' }),
});
exports.StaffTeacherWorkingHoursOrderParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.StaffWorkingHoursSchema, ['teacher_id', 'start_time']));
exports.StaffTeacherWorkingHoursFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.StaffTeacherWorkingHoursFilterParamsSchema,
    order: exports.StaffTeacherWorkingHoursOrderParamsSchema,
});
exports.StaffWorkingHoursSingleTeacherParamsSchema = typebox_1.Type.Object({
    teacher_id: typebox_1.Type.Integer(),
});
exports.StaffWorkingHoursSingleTeacherOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.StaffWorkingHoursSchema, ['start_time']));
exports.StaffTeacherWorkingHoursSingleTeacherFindParamsSchema = (0, responses_1.FindParams)({
    filters: typebox_1.Type.Null(),
    order: exports.StaffWorkingHoursSingleTeacherOrderParams,
});
exports.StaffWorkingHoursListResponseSchema = (0, responses_1.PaginatedList)(exports.StaffWorkingHoursSchema);
exports.StaffTeacherRelationsBaseSchema = typebox_1.Type.Object({
    senior_user_id: typebox_1.Type.Integer(),
    subordinate_user_id: typebox_1.Type.Integer(),
});
exports.StaffTeacherRelationsSchema = typebox_1.Type.Composite([
    exports.StaffTeacherRelationsBaseSchema,
    typebox_1.Type.Object({
        id: typebox_1.Type.Integer(),
    }),
]);
exports.StaffSubordinatesCreateSchema = typebox_1.Type.Object({
    senior_teacher: typebox_1.Type.Integer(),
    teacher_ids: typebox_1.Type.Array(typebox_1.Type.Integer(), { minItems: 1 }),
});
exports.StaffSubordinatesFilterParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    first_name: user_1.FirstNameSchema,
    middle_name: user_1.MiddleNameSchema,
    last_name: user_1.LastNameSchema,
    email: common_1.EmailSchema,
    code: typebox_1.Type.Integer(),
}));
exports.StaffSubordinatesOrderParamsSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.StaffSubordinatesFilterParamsSchema, ['email']),
    typebox_1.Type.Object({
        email: typebox_1.Type.Optional(common_1.WeakEmailSchema),
    }),
]);
exports.StaffSubordinatesFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.StaffSubordinatesFilterParamsSchema,
    order: typebox_1.Type.Partial(exports.StaffSubordinatesOrderParamsSchema),
});
exports.StaffSubordinatesResponseSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    user_id: typebox_1.Type.Integer(),
    first_name: user_1.FirstNameSchema,
    middle_name: user_1.MiddleNameSchema,
    last_name: user_1.LastNameSchema,
    email: common_1.EmailSchema,
    code: typebox_1.Type.Integer(),
});
exports.StaffSubordinatesResponseListSchema = (0, responses_1.PaginatedList)(exports.StaffSubordinatesResponseSchema);
exports.StaffDeleteSchema = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
