"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentsMimetypes = exports.PicturesMimetypes = exports.AllowedMimetypes = void 0;
var AllowedMimetypes;
(function (AllowedMimetypes) {
    AllowedMimetypes["png"] = "image/png";
    AllowedMimetypes["bmp"] = "image/bmp";
    AllowedMimetypes["jpg"] = "image/jpg";
    AllowedMimetypes["jpeg"] = "image/jpeg";
    AllowedMimetypes["pdf"] = "application/pdf";
    AllowedMimetypes["docx"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    AllowedMimetypes["doc"] = "application/msword";
})(AllowedMimetypes || (exports.AllowedMimetypes = AllowedMimetypes = {}));
var PicturesMimetypes;
(function (PicturesMimetypes) {
    PicturesMimetypes["png"] = "image/png";
    PicturesMimetypes["bmp"] = "image/bmp";
    PicturesMimetypes["jpg"] = "image/jpg";
    PicturesMimetypes["jpeg"] = "image/jpeg";
})(PicturesMimetypes || (exports.PicturesMimetypes = PicturesMimetypes = {}));
var DocumentsMimetypes;
(function (DocumentsMimetypes) {
    DocumentsMimetypes["pdf"] = "application/pdf";
    DocumentsMimetypes["docx"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    DocumentsMimetypes["doc"] = "application/msword";
})(DocumentsMimetypes || (exports.DocumentsMimetypes = DocumentsMimetypes = {}));
