"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SafeIntegerSchema = exports.NaturalIntegerSchema = exports.PositiveIntegerSchema = exports.S3ValidationDataSchema = exports.S3RemoveFileSchema = exports.S3GetFileSchema = exports.S3UploadFileDtoRamSchema = exports.S3UploadFileFromDiscSchema = exports.S3OperationSchema = exports.S3AccessRightsSchema = exports.S3AccessRightsPublicSchema = exports.S3AccessRightsStrictSchema = exports.S3BucketSchema = exports.S3BucketContentSchema = exports.S3FilePathSchema = exports.S3FilePathData = exports.FileMetaDataSchema = exports.UploadFilesSchema = exports.MediaFileSchema = exports.DocumentFileSchema = exports.AvatarFileSchema = exports.PictureFileSchema = exports.AvatarMaxFileSizeSchema = exports.DocumentMimetypeSchema = exports.PictureMimetypeSchema = exports.MediaFileMimetypesSchema = exports.DocumentsMimetypesSchema = exports.PicturesMimetypesSchema = exports.DefaultFileSizeMBSchema = exports.FileSchema = exports.AllowedMimetypesSchema = exports.FileContentSchema = exports.Nullable = exports.FileSize = exports.DateRangeArray = exports.FormLookupOption = exports.TimezoneSchema = exports.TimeHoursMinutesSchema = exports.UUIDSchema = exports.PasswordSchema = exports.WeakEmailSchema = exports.EmailSchema = exports.PhoneNumberSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const user_validation_rules_1 = require("../validation-rules/user.validation-rules");
const constants_1 = require("../entities/user/constants");
const constants_2 = require("../constants");
const types_1 = require("../types");
exports.PhoneNumberSchema = typebox_1.Type.String({
    minLength: 13,
    maxLength: 20,
    pattern: user_validation_rules_1.PHONE.source,
    format: 'phone-number',
});
exports.EmailSchema = typebox_1.Type.String({
    maxLength: constants_1.MAX_EMAIL_LENGTH,
    pattern: user_validation_rules_1.EMAIL.source,
    transform: ['toLowerCase'],
});
exports.WeakEmailSchema = typebox_1.Type.String({
    pattern: user_validation_rules_1.WEAK_EMAIL.source,
    maxLength: constants_1.MAX_EMAIL_LENGTH,
    minLength: constants_1.MIN_SEARCH_LENGTH,
    transform: ['toLowerCase'],
});
exports.PasswordSchema = typebox_1.Type.String({
    minLength: 6,
    maxLength: 100,
    format: 'password',
});
exports.UUIDSchema = typebox_1.Type.String({
    format: 'uuid',
});
exports.TimeHoursMinutesSchema = typebox_1.Type.String({
    pattern: user_validation_rules_1.TIME_HOURS_MINUTES.source,
});
const timezones = Intl.supportedValuesOf('timeZone');
exports.TimezoneSchema = typebox_1.Type.String({
    enum: timezones,
});
exports.FormLookupOption = typebox_1.Type.Object({ label: typebox_1.Type.String(), value: typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Number()]) });
exports.DateRangeArray = typebox_1.Type.Tuple([typebox_1.Type.String({ format: 'date-time' }), typebox_1.Type.String({ format: 'date-time' })]);
/**
 * @param {number} defaultSizeMB - file size in megabytes
 * @param {number} [minimum=0] - minimal file size
 */
const FileSize = (defaultSizeMB, minimum = 0) => typebox_1.Type.Integer({ minimum, default: defaultSizeMB * 1024 * 1024 });
exports.FileSize = FileSize;
const Nullable = (T) => typebox_1.Type.Union([typebox_1.Type.Null(), T]);
exports.Nullable = Nullable;
exports.FileContentSchema = typebox_1.Type.Unsafe({});
exports.AllowedMimetypesSchema = typebox_1.Type.Enum(types_1.AllowedMimetypes);
exports.FileSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Optional(typebox_1.Type.String()),
    filename: typebox_1.Type.String(),
    encoding: typebox_1.Type.String(),
    mimetype: exports.AllowedMimetypesSchema,
    file: exports.FileContentSchema,
    size: (0, exports.FileSize)(constants_2.DEFAULT_MAXIMUM_FILE_SIZE_MB),
});
exports.DefaultFileSizeMBSchema = typebox_1.Type.Integer({ maximum: constants_2.DEFAULT_MAXIMUM_FILE_SIZE_MB });
exports.PicturesMimetypesSchema = typebox_1.Type.Enum(types_1.PicturesMimetypes);
exports.DocumentsMimetypesSchema = typebox_1.Type.Enum(types_1.DocumentsMimetypes);
exports.MediaFileMimetypesSchema = typebox_1.Type.Union([
    typebox_1.Type.Literal('image/jpeg'),
    typebox_1.Type.Literal('image/jpg'),
    typebox_1.Type.Literal('image/png'),
    typebox_1.Type.Literal('image/gif'),
    typebox_1.Type.Literal('audio/mpeg'),
    typebox_1.Type.Literal('audio/wav'),
    typebox_1.Type.Literal('video/mp4'),
    typebox_1.Type.Literal('application/pdf'),
    typebox_1.Type.Literal('application/vnd.openxmlformats-officedocument.wordprocessingml.document'),
    typebox_1.Type.Literal('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
]);
exports.PictureMimetypeSchema = typebox_1.Type.Object({
    mimetype: typebox_1.Type.Enum(types_1.PicturesMimetypes),
});
exports.DocumentMimetypeSchema = typebox_1.Type.Object({
    mimetype: typebox_1.Type.Enum(types_1.DocumentsMimetypes),
});
exports.AvatarMaxFileSizeSchema = typebox_1.Type.Object({
    size: (0, exports.FileSize)(constants_2.AVATAR_MAXIMUM_FILE_SIZE_MB),
});
exports.PictureFileSchema = typebox_1.Type.Composite([typebox_1.Type.Omit(exports.FileSchema, ['mimetype']), exports.PictureMimetypeSchema]);
exports.AvatarFileSchema = typebox_1.Type.Composite([typebox_1.Type.Omit(exports.PictureFileSchema, ['size']), exports.AvatarMaxFileSizeSchema]);
exports.DocumentFileSchema = typebox_1.Type.Composite([typebox_1.Type.Omit(exports.FileSchema, ['mimetype']), exports.DocumentMimetypeSchema]);
exports.MediaFileSchema = typebox_1.Type.Composite([exports.FileSchema, exports.MediaFileMimetypesSchema]);
exports.UploadFilesSchema = typebox_1.Type.Record(typebox_1.Type.String(), exports.FileSchema);
exports.FileMetaDataSchema = typebox_1.Type.Object({
    'Content-Type': typebox_1.Type.String(),
});
exports.S3FilePathData = typebox_1.Type.Object({
    entityName: typebox_1.Type.String(),
    entityId: typebox_1.Type.Integer(),
    folder: typebox_1.Type.String(),
    fileName: typebox_1.Type.String(),
});
exports.S3FilePathSchema = typebox_1.Type.Array(typebox_1.Type.String());
exports.S3BucketContentSchema = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    access: exports.S3AccessRightsSchema,
    content: typebox_1.Type.Optional(typebox_1.Type.Record(typebox_1.Type.String(), Self)),
}));
exports.S3BucketSchema = typebox_1.Type.Record(typebox_1.Type.String(), exports.S3BucketContentSchema);
exports.S3AccessRightsStrictSchema = typebox_1.Type.Object({
    read: typebox_1.Type.Array(typebox_1.Type.Number()),
    write: typebox_1.Type.Array(typebox_1.Type.Number()),
});
exports.S3AccessRightsPublicSchema = typebox_1.Type.Literal('*');
exports.S3AccessRightsSchema = typebox_1.Type.Union([exports.S3AccessRightsStrictSchema, exports.S3AccessRightsPublicSchema]);
exports.S3OperationSchema = typebox_1.Type.Union([typebox_1.Type.Literal('read'), typebox_1.Type.Literal('write')]);
exports.S3UploadFileFromDiscSchema = typebox_1.Type.Object({
    objectName: typebox_1.Type.String(),
    filePath: typebox_1.Type.String(),
});
exports.S3UploadFileDtoRamSchema = typebox_1.Type.Object({
    objectName: typebox_1.Type.String(),
    file: exports.FileContentSchema,
    size: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    metaData: typebox_1.Type.Optional(exports.FileMetaDataSchema),
});
exports.S3GetFileSchema = typebox_1.Type.Object({
    objectName: typebox_1.Type.String(),
});
exports.S3RemoveFileSchema = typebox_1.Type.Object({
    objectName: typebox_1.Type.String(),
});
exports.S3ValidationDataSchema = typebox_1.Type.Object({
    userId: typebox_1.Type.Integer(),
});
exports.PositiveIntegerSchema = typebox_1.Type.Integer({ minimum: 0, maximum: Number.MAX_SAFE_INTEGER });
exports.NaturalIntegerSchema = typebox_1.Type.Integer({ minimum: 1, maximum: Number.MAX_SAFE_INTEGER });
exports.SafeIntegerSchema = typebox_1.Type.Integer({ minimum: Number.MIN_SAFE_INTEGER, maximum: Number.MAX_SAFE_INTEGER });
