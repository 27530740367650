"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COURSE_CONTENT_TYPES = exports.MAX_CLASSROOM_SIZE_MIN_VALUE = exports.GRADE_MAX_VALUE = exports.GRADE_MIN_VALUE = exports.MAX_COURSE_IN_STUDY_PLAN = exports.MAX_ITEMS_COUNT_PER_COURSE = void 0;
exports.MAX_ITEMS_COUNT_PER_COURSE = 250;
exports.MAX_COURSE_IN_STUDY_PLAN = 50;
exports.GRADE_MIN_VALUE = 1;
exports.GRADE_MAX_VALUE = 11;
exports.MAX_CLASSROOM_SIZE_MIN_VALUE = 0;
var COURSE_CONTENT_TYPES;
(function (COURSE_CONTENT_TYPES) {
    COURSE_CONTENT_TYPES["Topic"] = "Topic";
    COURSE_CONTENT_TYPES["Lesson"] = "Lesson";
    COURSE_CONTENT_TYPES["Test"] = "Test";
})(COURSE_CONTENT_TYPES || (exports.COURSE_CONTENT_TYPES = COURSE_CONTENT_TYPES = {}));
