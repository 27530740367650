"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCodes = exports.ValidationErrors = void 0;
var ValidationErrors;
(function (ValidationErrors) {
    // Common
    ValidationErrors["REQUIRED"] = "required";
    ValidationErrors["EQUAL_LENGTH"] = "equalLength";
    ValidationErrors["MIN"] = "min";
    ValidationErrors["MIN_LENGTH"] = "minLength";
    ValidationErrors["MAX"] = "max";
    ValidationErrors["MAX_LENGTH"] = "maxLength";
    // Formats
    ValidationErrors["FORMAT"] = "format";
    ValidationErrors["LETTERS"] = "letters";
    ValidationErrors["NUMBERS"] = "numbers";
    ValidationErrors["PATTERN"] = "pattern";
    ValidationErrors["EMAIL"] = "email";
    ValidationErrors["INCORRECT_EMAIL"] = "incorrectEmail";
    ValidationErrors["INCORRECT_PASSWORD"] = "incorrectPassword";
    ValidationErrors["INCORRECT_ROLE"] = "incorrectRole";
    ValidationErrors["CONFIRM_PASSWORD"] = "confirmPassword";
    ValidationErrors["PASSWORD"] = "password";
    ValidationErrors["MISMATCH"] = "mismatch";
    // Date
    ValidationErrors["PAST"] = "past";
    ValidationErrors["FUTURE"] = "future";
    ValidationErrors["EXPIRED"] = "expired";
    ValidationErrors["NOT_IN_RANGE"] = "notInRange";
    // Uniq/exist in DB/assignments
    ValidationErrors["EXIST"] = "exist";
    ValidationErrors["NOT_EXIST"] = "notExists";
    ValidationErrors["IN_USAGE"] = "inUsage";
    ValidationErrors["ALREADY_CREATED"] = "alreadyCreated";
    ValidationErrors["AT_LEAST_ONE_SHOULD_EXIST"] = "atLeastOneShouldExist";
    ValidationErrors["EXIST_WITH_CONJUNCTION"] = "existWithConjunction";
    ValidationErrors["ASSIGNED"] = "assigned";
    // Tries (Send code / login tries etc)
    ValidationErrors["MAX_ATTEMPTS"] = "maxAttempts";
    ValidationErrors["CAN_NOT_DO_NEXT_TRY"] = "canNotDoNextTry";
    ValidationErrors["COOLDOWN"] = "cooldown";
    // Unknown
    ValidationErrors["SOMETHING_WENT_WRONG"] = "somethingWentWrong";
})(ValidationErrors || (exports.ValidationErrors = ValidationErrors = {}));
var ErrorCodes;
(function (ErrorCodes) {
    // Default
    ErrorCodes["DEFAULT"] = "default";
    // Calendar events
    ErrorCodes["CALENDAR_NOTHING_TO_BUILD"] = "c30000";
    // Packages
    ErrorCodes["SCHOOL_YEAR_OF_AGE_GROUPS_AND_PACKAGE_INCOMPATIBLE"] = "p10000";
    ErrorCodes["GRADE_OF_AGE_GROUPS_NOT_UNIQ"] = "p10001";
    // Documents
    ErrorCodes["DOCUMENT_HAVE_TO_BE_IN_UPLOADED_OR_EMPTY_STATUS"] = "d10000";
})(ErrorCodes || (exports.ErrorCodes = ErrorCodes = {}));
