import clsx from 'clsx'
import { PropsWithChildren } from 'react'

interface MenuCollapseContainerProps extends PropsWithChildren {
  className?: string
  isOpen: boolean
}

export const MenuCollapseContainer = ({ children, isOpen, className }: MenuCollapseContainerProps) => {
  return (
    <ul
      tabIndex={-1}
      className={clsx('flex flex-col gap-2 transition-all duration-150 ease-in-out will-change-contents', {
        'mt-2 max-h-dvh': isOpen,
        'pointer-events-none invisible max-h-0 select-none overflow-hidden': !isOpen,
        className,
      })}
    >
      {children}
    </ul>
  )
}
