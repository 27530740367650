import { FC, lazy, Suspense } from 'react'
import { ErrorBoundary } from '@repo/ui/ErrorBoundary'
import ModuleSpinner from '@repo/ui/ModuleSpinner'
import LocalizationGuard from 'src/shared/guards/LocalizationGuard'
import RouterPermissionsGuard from 'src/shared/guards/RouterPermissionsGuard'

const DocumentsValidation = lazy(() => import('../../modules/DocumentsValidationModule'))

const DocumentsValidationPage: FC = () => {
  return (
    <ErrorBoundary>
      <RouterPermissionsGuard permissions={{ CoursesManagement: ['read', 'edit'] }}>
        <LocalizationGuard namespace='documents-validation'>
          <Suspense fallback={<ModuleSpinner />}>
            <DocumentsValidation />
          </Suspense>
        </LocalizationGuard>
      </RouterPermissionsGuard>
    </ErrorBoundary>
  )
}

export default DocumentsValidationPage
