import { CALENDAR_FORMATS } from '../constants'
import { format } from 'date-fns'
import { LITERALS } from '../../constants'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { clsx } from 'clsx'
import { ExtendedEvent } from '../index'

// TODO: Think about how we will work with expanding event interfaces

interface WeekAndDayEventProps<T> {
  event: T
  isFrontOffice?: boolean
}

const persons = [1, 2, 3, 4, 5]

const calendarColors = [
  'bg-calendar-blue-default text-calendar-blue-text',
  'bg-calendar-green-default text-calendar-green-text',
  'bg-calendar-red-default text-calendar-red-text',
  'bg-calendar-purple-default text-calendar-purple-text',
]

const WeekAndDayEvent = <T extends ExtendedEvent>({ event, isFrontOffice }: WeekAndDayEventProps<T>) => {
  const startTime = event.start ? format(event.start, CALENDAR_FORMATS.TIME) : null
  const endTime = event.end ? format(event.end, CALENDAR_FORMATS.TIME) : null

  if (isFrontOffice) {
    const eventIndex = +event?.id || 0
    const colorClass = calendarColors[eventIndex % calendarColors.length]

    return (
      <div className={clsx('flex h-full grow flex-col justify-between rounded-md px-2 py-1', colorClass)}>
        <div>
          <div className="flex items-center justify-between">
            <p className="text-xs uppercase">{'Урок'}</p>
            <button onClick={() => {}} className="flex">
              <EllipsisVerticalIcon className="relative left-3.5 size-6" />
              <EllipsisVerticalIcon className="size-6" />
            </button>
          </div>
          <div className="mb-4 flex flex-col gap-1">
            <p className="text-gray-900">{event.title}</p>
            <p className="text-sm">{`${startTime} - ${endTime}`}</p>
          </div>
        </div>
        <div className="flex">
          {persons
            ?.slice(0, 3)
            ?.map((person: number) => (
              <img
                key={person}
                className="-ml-2 size-8 rounded-full border border-white first:ml-0"
                src="/assets/ava.png"
                alt="icon"
              />
            ))}
          {persons?.length > 3 && (
            <div className="-ml-2 flex size-8 items-center justify-center rounded-full border border-white bg-[#CCE1FF] text-xs">
              {`+${persons.length - 3}`}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-2 whitespace-nowrap px-2 py-1">
      <span>{event.title}</span>
      <span className="text-gray-400">{`${startTime} ${LITERALS.DASH} ${endTime}`}</span>
    </div>
  )
}

export default WeekAndDayEvent
