import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const PackageListPage = lazy(() => import('src/pages/PackagesPages/PackageListPage'))

export default [
  {
    path: ROUTES.PACKAGES.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <PackageListPage />
      </Suspense>
    ),
    isScrollable: false,
  },
  {
    path: ROUTES.PACKAGES.CREATE,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <div>Not Implemented</div>
      </Suspense>
    ),
  },
  {
    path: ROUTES.PACKAGES.EDIT,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <div>Not Implemented</div>
      </Suspense>
    ),
  },
]
