"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./baseEntity"), exports);
__exportStar(require("./user"), exports);
__exportStar(require("./auth"), exports);
__exportStar(require("./role"), exports);
__exportStar(require("./organization"), exports);
__exportStar(require("./position"), exports);
__exportStar(require("./permission"), exports);
__exportStar(require("./course"), exports);
__exportStar(require("./study-plan"), exports);
__exportStar(require("./topic"), exports);
__exportStar(require("./lesson"), exports);
__exportStar(require("./calendar-plan"), exports);
__exportStar(require("./classroom"), exports);
__exportStar(require("./user-group"), exports);
__exportStar(require("./question"), exports);
__exportStar(require("./school-year"), exports);
__exportStar(require("./test"), exports);
__exportStar(require("./grade"), exports);
__exportStar(require("./promocodes"), exports);
__exportStar(require("./question-tag"), exports);
__exportStar(require("./question-answer"), exports);
__exportStar(require("./package-groups"), exports);
__exportStar(require("./package-specialization"), exports);
__exportStar(require("./packages"), exports);
__exportStar(require("./record-card"), exports);
__exportStar(require("./completion-criteria"), exports);
__exportStar(require("./homework"), exports);
__exportStar(require("./media"), exports);
__exportStar(require("./course-evaluation"), exports);
__exportStar(require("./staff"), exports);
