import { PropsWithChildren } from 'react'
import { useAsideContext } from '@repo/ui/PrivateLayout'
import clsx from 'clsx'

interface MenuListProps extends PropsWithChildren {}

export const MenuList = ({ children }: MenuListProps) => {
  const isAsideOpen = useAsideContext()

  return (
    <ul
      className={clsx(
        {
          'items-center': !isAsideOpen,
        },
        'flex flex-1 flex-col gap-4 overflow-y-auto',
      )}
    >
      {children}
    </ul>
  )
}
