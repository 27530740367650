import { createBrowserRouter } from 'react-router-dom'
import ROUTES from './constants'
import CourseRoutes from './routes/CourseRoutes'
import StudyPlanRoutes from './routes/StudyPlanRoutes'
import AgeGroupRoutes from './routes/AgeGroupRoutes'
import UserRoutes from './routes/UserRoutes'
import DictionaryRoutes from './routes/DictionaryRoutes'
import RoleRoutes from './routes/RoleRoutes'
import PositionRoutes from './routes/PositionRoutes'
import ServiceRoutes from './routes/ServiceRoutes'
import AuthRoutes from './routes/AuthRoutes'
import HomePage from 'src/pages/HomePage'
import CalendarPage from 'src/pages/CalendarPage'
import LessonsRoutes from './routes/LessonsRoutes'
import PromoCodeRoutes from './routes/PromoCodeRoutes.tsx'
import TestsRoutes from './routes/TestsRoutes.tsx'
import { ReactNode } from 'react'
import { PrivateLayoutRouterWrapper } from 'src/components/Layouts/PrivateLayout/components/PrivateLayoutRouterWrapper'
import RelationshipsRoutes from './routes/RelationshipsRoutes.tsx'
import PackagesRoutes from './routes/PackagesRoutes.tsx'
import DocumentsValidationPage from 'src/pages/DocumentsValidationPage'
import WorkspaceRoutes from 'src/app/router/routes/WorkspaceRoutes.tsx'

export interface RouteDefinition {
  path: string
  element: ReactNode
  index?: boolean
  isScrollable?: boolean
}

const privateRoutes: RouteDefinition[] = [
  {
    index: true,
    path: ROUTES.HOME,
    element: <HomePage />,
  },
  {
    path: ROUTES.CALENDAR,
    element: <CalendarPage />,
  },
  ...WorkspaceRoutes,
  {
    path: ROUTES.DOCUMENTS_VALIDATION,
    element: <DocumentsValidationPage />,
  },
  ...CourseRoutes,
  ...StudyPlanRoutes,
  ...AgeGroupRoutes,
  ...UserRoutes,
  ...DictionaryRoutes,
  ...RoleRoutes,
  ...PositionRoutes,
  ...ServiceRoutes,
  ...LessonsRoutes,
  ...PromoCodeRoutes,
  ...TestsRoutes,
  ...RelationshipsRoutes,
  ...PackagesRoutes,
]

export const ROUTER = createBrowserRouter([
  ...AuthRoutes,
  {
    path: '/',
    element: <PrivateLayoutRouterWrapper routes={privateRoutes} />,
    children: privateRoutes,
  },
])
