import { CALENDAR_FORMATS } from '../constants'
import { format } from 'date-fns'
import { ExtendedEvent } from '../index'
import { clsx } from 'clsx'

interface MonthEventProps<T> {
  event: T
  isFrontOffice?: boolean
}

const calendarColors = [
  'bg-calendar-blue-default',
  'bg-calendar-green-default',
  'bg-calendar-red-default',
  'bg-calendar-purple-default',
]

const MonthEvent = <T extends ExtendedEvent>({ event, isFrontOffice }: MonthEventProps<T>) => {
  const startTime = event.start ? format(event.start, CALENDAR_FORMATS.TIME) : null
  const eventIndex = +event?.id || 0
  const colorClass = calendarColors[eventIndex % calendarColors.length]

  return (
    <div className={clsx('flex justify-between whitespace-nowrap px-2 py-1 text-xs', colorClass)}>
      <span>{event.title}</span>
      {isFrontOffice && <span>{`${startTime}`}</span>}
    </div>
  )
}

export default MonthEvent
