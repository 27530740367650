"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionUserAnswerRateSchema = exports.QuestionAnswerFileUploadDeleteSchema = exports.QuestionAnswerFileUploadResultSchema = exports.QuestionAnswerFailedFileUploadItemSchema = exports.TestAttemptContinuePayload = exports.TestAttemptFinishPayload = exports.QuestionAnswerPointsSchema = exports.QuestionAnswerCorrectnessSchema = exports.QuestionAnswerSchema = exports.QuestionAnswerAbstractSchema = exports.QuestionAnswerWithFileUploadSchema = exports.QuestionAnswerWithEssaySchema = exports.QuestionAnswerWithExactAnswerSchema = exports.QuestionAnswerWithSortedGroupsSchema = exports.QuestionAnswerWithMatchedPairsSchema = exports.QuestionAnswerWithRankingSchema = exports.QuestionAnswerWithMultipleChoiceSchema = exports.QuestionAnswerWithWithSingleChoiceSchema = exports.TestQuestionFileUploadPayload = exports.TestQuestionAnswerFileUploadSchema = exports.TestQuestionUserAnswerExtendedPayload = exports.TestQuestionUserAnswerPayload = exports.TestQuestionFileUploadResolvedPayload = exports.TestQuestionEssayPayload = exports.TestQuestionExactAnswerPayload = exports.TestQuestionSortedGroupsPayload = exports.TestQuestionMatchedPairsPayload = exports.TestQuestionRankingPayload = exports.TestQuestionMultipleChoicePayload = exports.TestQuestionSingleChoicePayload = exports.QuestionIdSchema = exports.QuestionAnswerFileUploadSchema = exports.QuestionAnswerSuccessFileUploadItemSchema = exports.QuestionAnswerEssaySchema = exports.QuestionAnswerExactAnswerSchema = exports.QuestionAnswerSortedGroupsSchema = exports.SortedGroup = exports.QuestionAnswerMatchedPairsSchema = exports.MatchedPairsSchema = exports.QuestionAnswerMatchedPairsOptionSchema = exports.QuestionAnswerRankingSchema = exports.QuestionAnswerMultipleChoiceSchema = exports.QuestionAnswerSingleChoiceSchema = exports.QuestionAnswerBaseSchema = exports.QuestionAnswerDataSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const common_1 = require("../../common");
const utils_1 = require("../../utils");
const question_1 = require("../question");
const test_1 = require("../test");
const baseEntity_1 = require("../baseEntity");
const types_1 = require("./types");
exports.QuestionAnswerDataSchema = typebox_1.Type.Object({
    user_id: typebox_1.Type.Integer(),
    attempt_id: typebox_1.Type.Integer(),
    question_id: typebox_1.Type.Integer(),
    answer_type: typebox_1.Type.Enum(types_1.AnswerType),
    score: (0, common_1.Nullable)(test_1.ScoreSchema),
    comment: (0, common_1.Nullable)(typebox_1.Type.String()),
    reviewer_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
    reviewed_at: (0, common_1.Nullable)(typebox_1.Type.String({
        format: 'date-time',
    })),
});
exports.QuestionAnswerBaseSchema = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.QuestionAnswerDataSchema]);
exports.QuestionAnswerSingleChoiceSchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.SingleChoice),
    response_data: (0, common_1.Nullable)(typebox_1.Type.Object({
        selected_option: typebox_1.Type.Integer(),
    })),
}, { additionalProperties: false });
exports.QuestionAnswerMultipleChoiceSchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.MultipleChoice),
    response_data: (0, common_1.Nullable)(typebox_1.Type.Object({
        selected_options: typebox_1.Type.Array(typebox_1.Type.Integer()),
    })),
}, { additionalProperties: false });
exports.QuestionAnswerRankingSchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.Ranking),
    response_data: (0, common_1.Nullable)(typebox_1.Type.Object({
        ranked_options: typebox_1.Type.Array(typebox_1.Type.Integer()),
    })),
}, { additionalProperties: false });
exports.QuestionAnswerMatchedPairsOptionSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String(),
});
exports.MatchedPairsSchema = typebox_1.Type.Object({
    left: typebox_1.Type.Integer(),
    right: typebox_1.Type.Integer(),
});
exports.QuestionAnswerMatchedPairsSchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.MatchedPairs),
    response_data: (0, common_1.Nullable)(typebox_1.Type.Object({
        matched_pairs: typebox_1.Type.Array(exports.MatchedPairsSchema),
    })),
}, { additionalProperties: false });
exports.SortedGroup = typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Array(typebox_1.Type.Number()));
exports.QuestionAnswerSortedGroupsSchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.Sorting),
    response_data: (0, common_1.Nullable)(typebox_1.Type.Object({
        sorted_groups: exports.SortedGroup,
    })),
});
exports.QuestionAnswerExactAnswerSchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.TextInput),
    response_data: (0, common_1.Nullable)(typebox_1.Type.Object({
        exact_answer: typebox_1.Type.String(),
    })),
}, { additionalProperties: false });
exports.QuestionAnswerEssaySchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.Essay),
    response_data: (0, common_1.Nullable)(typebox_1.Type.Object({
        essay: typebox_1.Type.String(),
    })),
}, { additionalProperties: false });
exports.QuestionAnswerSuccessFileUploadItemSchema = typebox_1.Type.Object({
    file_url: typebox_1.Type.String(),
    id: typebox_1.Type.String(),
});
exports.QuestionAnswerFileUploadSchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.FileUpload),
    response_data: (0, common_1.Nullable)(typebox_1.Type.Object({
        files: typebox_1.Type.Array(exports.QuestionAnswerSuccessFileUploadItemSchema),
    })),
}, { additionalProperties: false });
exports.QuestionIdSchema = typebox_1.Type.Object({
    question_id: typebox_1.Type.Integer(),
});
exports.TestQuestionSingleChoicePayload = typebox_1.Type.Composite([exports.QuestionIdSchema, exports.QuestionAnswerSingleChoiceSchema]);
exports.TestQuestionMultipleChoicePayload = typebox_1.Type.Composite([exports.QuestionIdSchema, exports.QuestionAnswerMultipleChoiceSchema]);
exports.TestQuestionRankingPayload = typebox_1.Type.Composite([exports.QuestionIdSchema, exports.QuestionAnswerRankingSchema]);
exports.TestQuestionMatchedPairsPayload = typebox_1.Type.Composite([exports.QuestionIdSchema, exports.QuestionAnswerMatchedPairsSchema]);
exports.TestQuestionSortedGroupsPayload = typebox_1.Type.Composite([exports.QuestionIdSchema, exports.QuestionAnswerSortedGroupsSchema]);
exports.TestQuestionExactAnswerPayload = typebox_1.Type.Composite([exports.QuestionIdSchema, exports.QuestionAnswerExactAnswerSchema]);
exports.TestQuestionEssayPayload = typebox_1.Type.Composite([exports.QuestionIdSchema, exports.QuestionAnswerEssaySchema]);
exports.TestQuestionFileUploadResolvedPayload = typebox_1.Type.Composite([exports.QuestionIdSchema, exports.QuestionAnswerFileUploadSchema]);
exports.TestQuestionUserAnswerPayload = (0, utils_1.OneOf)([
    exports.TestQuestionSingleChoicePayload,
    exports.TestQuestionMultipleChoicePayload,
    exports.TestQuestionRankingPayload,
    exports.TestQuestionMatchedPairsPayload,
    exports.TestQuestionSortedGroupsPayload,
    exports.TestQuestionExactAnswerPayload,
    exports.TestQuestionEssayPayload,
], {
    type: 'object',
    discriminator: { propertyName: 'response_type' },
    required: ['response_type'],
});
exports.TestQuestionUserAnswerExtendedPayload = (0, utils_1.OneOf)([
    exports.TestQuestionSingleChoicePayload,
    exports.TestQuestionMultipleChoicePayload,
    exports.TestQuestionRankingPayload,
    exports.TestQuestionMatchedPairsPayload,
    exports.TestQuestionSortedGroupsPayload,
    exports.TestQuestionExactAnswerPayload,
    exports.TestQuestionEssayPayload,
    exports.TestQuestionFileUploadResolvedPayload,
], {
    type: 'object',
    discriminator: { propertyName: 'response_type' },
    required: ['response_type'],
});
exports.TestQuestionAnswerFileUploadSchema = typebox_1.Type.Object({
    files: typebox_1.Type.Array(common_1.FileSchema),
}, { additionalProperties: false });
exports.TestQuestionFileUploadPayload = typebox_1.Type.Composite([exports.QuestionIdSchema, exports.TestQuestionAnswerFileUploadSchema]);
exports.QuestionAnswerWithWithSingleChoiceSchema = typebox_1.Type.Composite([
    exports.QuestionAnswerBaseSchema,
    exports.QuestionAnswerSingleChoiceSchema,
]);
exports.QuestionAnswerWithMultipleChoiceSchema = typebox_1.Type.Composite([
    exports.QuestionAnswerBaseSchema,
    exports.QuestionAnswerMultipleChoiceSchema,
]);
exports.QuestionAnswerWithRankingSchema = typebox_1.Type.Composite([exports.QuestionAnswerBaseSchema, exports.QuestionAnswerRankingSchema]);
exports.QuestionAnswerWithMatchedPairsSchema = typebox_1.Type.Composite([
    exports.QuestionAnswerBaseSchema,
    exports.QuestionAnswerMatchedPairsSchema,
]);
exports.QuestionAnswerWithSortedGroupsSchema = typebox_1.Type.Composite([
    exports.QuestionAnswerBaseSchema,
    exports.QuestionAnswerSortedGroupsSchema,
]);
exports.QuestionAnswerWithExactAnswerSchema = typebox_1.Type.Composite([
    exports.QuestionAnswerBaseSchema,
    exports.QuestionAnswerExactAnswerSchema,
]);
exports.QuestionAnswerWithEssaySchema = typebox_1.Type.Composite([exports.QuestionAnswerBaseSchema, exports.QuestionAnswerEssaySchema]);
exports.QuestionAnswerWithFileUploadSchema = typebox_1.Type.Composite([
    exports.QuestionAnswerBaseSchema,
    exports.QuestionAnswerFileUploadSchema,
]);
exports.QuestionAnswerAbstractSchema = typebox_1.Type.Composite([
    exports.QuestionAnswerBaseSchema,
    typebox_1.Type.Object({
        response_type: typebox_1.Type.Enum(question_1.QuestionType),
        response_data: typebox_1.Type.Unknown(),
    }),
]);
exports.QuestionAnswerSchema = (0, utils_1.OneOf)([
    exports.QuestionAnswerWithWithSingleChoiceSchema,
    exports.QuestionAnswerWithMultipleChoiceSchema,
    exports.QuestionAnswerWithRankingSchema,
    exports.QuestionAnswerWithMatchedPairsSchema,
    exports.QuestionAnswerWithSortedGroupsSchema,
    exports.QuestionAnswerWithExactAnswerSchema,
    exports.QuestionAnswerWithEssaySchema,
    exports.QuestionAnswerWithFileUploadSchema,
], {
    type: 'object',
    discriminator: { propertyName: 'response_type' },
    required: ['response_type'],
});
exports.QuestionAnswerCorrectnessSchema = typebox_1.Type.Object({
    answer_type: typebox_1.Type.Enum(types_1.AnswerType),
});
exports.QuestionAnswerPointsSchema = typebox_1.Type.Object({
    score: question_1.QuestionSignedPointsSchema,
});
exports.TestAttemptFinishPayload = typebox_1.Type.Object({
    attempt_id: typebox_1.Type.Integer(),
});
exports.TestAttemptContinuePayload = exports.TestAttemptFinishPayload;
exports.QuestionAnswerFailedFileUploadItemSchema = typebox_1.Type.Object({
    filename: typebox_1.Type.String(),
});
exports.QuestionAnswerFileUploadResultSchema = typebox_1.Type.Object({
    succeeded_uploads: typebox_1.Type.Array(exports.QuestionAnswerSuccessFileUploadItemSchema),
    failed_uploads: typebox_1.Type.Array(exports.QuestionAnswerFailedFileUploadItemSchema),
});
exports.QuestionAnswerFileUploadDeleteSchema = typebox_1.Type.Object({
    question_id: typebox_1.Type.Integer(),
    file_paths: typebox_1.Type.Array(typebox_1.Type.String()),
});
exports.QuestionUserAnswerRateSchema = typebox_1.Type.Pick(exports.QuestionAnswerDataSchema, ['score']);
