"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaShortParamsSchema = exports.MediaToTagsSchema = exports.MediaTagsFindResponseSchema = exports.MediaTagsFindParamsSchema = exports.MediaTagsFindOrderParams = exports.MediaTagsFindFilterParamsSchema = exports.MediaTagsSchemaResponse = exports.MediaTagsParamsSchema = exports.MediaTagsCreateUpdateSchema = exports.MediaTagsSchema = exports.MediaUploadResponseSchema = exports.MediaParamsSchema = exports.MediaFilesFindResponseSchema = exports.MediaFilesSchemaResponse = exports.MediaFilesFindPayloadSchema = exports.MediaFileResponseSchema = exports.MediaSchema = exports.MediaFileUrlPathSchema = exports.MediaUploadRequestSchema = exports.MediaCheckDeleteAccessParamsSchema = exports.MediaCheckAccessParamsSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
const common_1 = require("../../common");
const constants_1 = require("./constants");
const user_1 = require("../user");
// Media
const MediaFileTypesSchema = typebox_1.Type.Enum(constants_1.FileTypes);
exports.MediaCheckAccessParamsSchema = typebox_1.Type.Object({
    accessLevel: typebox_1.Type.Enum(constants_1.MediaAccessLevel),
    mediaUserId: typebox_1.Type.Integer(),
    userId: typebox_1.Type.Integer(),
    userSystemRole: typebox_1.Type.Enum(user_1.SystemRole),
});
exports.MediaCheckDeleteAccessParamsSchema = typebox_1.Type.Omit(exports.MediaCheckAccessParamsSchema, ['accessLevel']);
exports.MediaUploadRequestSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    file: common_1.MediaFileSchema,
    accessLevel: typebox_1.Type.Enum(constants_1.MediaAccessLevel),
    tags: typebox_1.Type.Array(typebox_1.Type.Number()),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.String(),
}));
exports.MediaFileUrlPathSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    url: (0, common_1.Nullable)(typebox_1.Type.String()),
});
exports.MediaSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    user_id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.String(),
    original_url: typebox_1.Type.String(),
    short_url: typebox_1.Type.String(),
    file_type: typebox_1.Type.String(),
    size: typebox_1.Type.Integer(),
    access_level: typebox_1.Type.Union([typebox_1.Type.Literal(constants_1.MediaAccessLevel.PRIVATE), typebox_1.Type.Literal(constants_1.MediaAccessLevel.PUBLIC)]),
    created_by: typebox_1.Type.Integer(),
    updated_by: (0, common_1.Nullable)(typebox_1.Type.Integer()),
}, { additionalProperties: false });
exports.MediaFileResponseSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    user_id: typebox_1.Type.Number(),
    user_first_name: typebox_1.Type.String(),
    user_last_name: typebox_1.Type.String(),
    user_middle_name: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.String(),
    url: typebox_1.Type.String(),
    file_type: MediaFileTypesSchema,
    size: typebox_1.Type.Number(),
    access_level: typebox_1.Type.Union([typebox_1.Type.Literal(constants_1.MediaAccessLevel.PRIVATE), typebox_1.Type.Literal(constants_1.MediaAccessLevel.PUBLIC)]),
    created_at: typebox_1.Type.String(),
    updated_at: typebox_1.Type.String(),
});
exports.MediaFilesFindPayloadSchema = (0, responses_1.FindParams)({
    filters: typebox_1.Type.Object({
        name: typebox_1.Type.Optional(typebox_1.Type.String()),
        file_type: typebox_1.Type.Optional(MediaFileTypesSchema),
        author: typebox_1.Type.Optional(typebox_1.Type.Number()),
        created_at_start: typebox_1.Type.Optional(typebox_1.Type.String()),
        created_at_end: typebox_1.Type.Optional(typebox_1.Type.String()),
    }),
    order: typebox_1.Type.Partial(typebox_1.Type.Pick(exports.MediaFileResponseSchema, [
        'created_at',
        'size',
        'user_last_name',
    ])),
});
exports.MediaFilesSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.MediaFileResponseSchema], {
    additionalProperties: false,
});
exports.MediaFilesFindResponseSchema = (0, responses_1.PaginatedList)(exports.MediaFileResponseSchema);
exports.MediaParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
});
exports.MediaUploadResponseSchema = exports.MediaFileUrlPathSchema;
// Media Tags
exports.MediaTagsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String({ minLength: 1, maxLength: 50 }),
}, { additionalProperties: false });
exports.MediaTagsCreateUpdateSchema = typebox_1.Type.Pick(exports.MediaTagsSchema, ['name']);
exports.MediaTagsParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.MediaTagsSchemaResponse = typebox_1.Type.Composite([typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['id']), exports.MediaTagsSchema], {
    additionalProperties: false,
});
exports.MediaTagsFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.MediaTagsFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.MediaTagsSchemaResponse, ['name']));
exports.MediaTagsFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.MediaTagsFindFilterParamsSchema,
    order: exports.MediaTagsFindOrderParams,
});
exports.MediaTagsFindResponseSchema = typebox_1.Type.Object({
    items: typebox_1.Type.Array(exports.MediaTagsSchema),
    total: typebox_1.Type.Integer(),
});
// Media To Tags
exports.MediaToTagsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    media_id: typebox_1.Type.Integer(),
    tag_id: typebox_1.Type.Integer(),
}, { additionalProperties: false });
// Media short url
exports.MediaShortParamsSchema = typebox_1.Type.Object({
    shortUrl: typebox_1.Type.String(),
});
