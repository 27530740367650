const ROUTES = {
  HOME: '/',
  CALENDAR: '/calendar',
  DICTIONARIES: '/dictionaries',
  FORBIDDEN: '/forbidden',
  AUTH: {
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    SIGN_UP_CONFIRMATION: '/sign-up/confirmation',
    CONFIRM_SSO: '/confirm-sso',
    RECOVER_PASSWORD: '/recover-password',
    CHANGE_PASSWORD: '/change-password',
  },
  WORKSPACE: {
    STAFF_MANAGEMENT: '/staff-management',
    STAFF_MANAGEMENT_PROFILE: '/staff-management/teacher/:id',
  },
  DOCUMENTS_VALIDATION: '/documents-validation',
  COURSES: {
    LIST: '/courses',
    CREATE: '/courses/create',
    EDIT: '/courses/:id',
  },
  LESSONS: {
    ELEMENTS: '/lessons/:id/elements',
    PREVIEW: '/lessons/:id/preview',
  },
  STUDY_PLANS: {
    LIST: '/study-plans',
    CREATE: '/study-plans/create',
    EDIT: '/study-plans/:id',
    CALENDAR_PLAN: '/study-plans/:id/calendar-plans/:calendarPlanId',
  },
  PROMOCODES: {
    LIST: '/promocodes',
  },
  RELATIONSHIPS: {
    LIST: '/relationships',
  },
  AGE_GROUPS: {
    LIST: '/age-groups',
    EDIT: '/age-groups/:id',
  },
  CATEGORIES: {
    LIST: '/categories',
  },
  TAGS: {
    LIST: '/tags',
  },
  USERS: {
    PROFILE: '/profile/:id',
    LIST: '/users',
  },
  ROLES: {
    LIST: '/roles',
    DETAILS: '/roles/:id',
  },
  POSITIONS: {
    LIST: '/positions',
    EDIT: '/positions/:id',
  },
  BANK_QUESTION: {
    LIST: '/bank-questions',
    CREATE: '/bank-questions/create',
    EDIT: '/bank-questions/:id',
  },
  TESTS: {
    LIST: '/tests',
    CREATE: '/tests/create',
    EDIT: '/tests/:id',
  },
  PACKAGES: {
    LIST: '/packages',
    CREATE: '/packages/create',
    EDIT: '/packages/:id',
  },
}

export default ROUTES
