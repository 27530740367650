"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_MAXIMUM_FILE_SIZE_BYTES = exports.HOMEWORK_MAXIMUM_FILE_SIZE_MB = exports.HOMEWORK_MINIMUM_LIMITATION_FILE_SIZE_MB = exports.DEFAULT_MAXIMUM_FILE_SIZE_MB = exports.AVATAR_MAXIMUM_FILE_SIZE_MB = exports.DEFAULT_MAXIMUM_FILE_COUNT = exports.DEFAULT_FILE_SIZE_MB = exports.POSTGRES_MAX_VARCHAR_SIZE = void 0;
exports.POSTGRES_MAX_VARCHAR_SIZE = 65535;
exports.DEFAULT_FILE_SIZE_MB = 20;
exports.DEFAULT_MAXIMUM_FILE_COUNT = 10;
exports.AVATAR_MAXIMUM_FILE_SIZE_MB = 5;
exports.DEFAULT_MAXIMUM_FILE_SIZE_MB = 50;
exports.HOMEWORK_MINIMUM_LIMITATION_FILE_SIZE_MB = 1;
exports.HOMEWORK_MAXIMUM_FILE_SIZE_MB = 20;
exports.DEFAULT_MAXIMUM_FILE_SIZE_BYTES = 5000000;
