"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileTypes = exports.MediaAccessLevel = void 0;
var MediaAccessLevel;
(function (MediaAccessLevel) {
    MediaAccessLevel["PRIVATE"] = "PRIVATE";
    MediaAccessLevel["PUBLIC"] = "PUBLIC";
})(MediaAccessLevel || (exports.MediaAccessLevel = MediaAccessLevel = {}));
var FileTypes;
(function (FileTypes) {
    FileTypes["JPG"] = "JPG";
    FileTypes["JPEG"] = "JPEG";
    FileTypes["PNG"] = "PNG";
    FileTypes["GIF"] = "GIF";
    FileTypes["MP3"] = "MP3";
    FileTypes["WAV"] = "WAV";
    FileTypes["MP4"] = "MP4";
    FileTypes["PDF"] = "PDF";
    FileTypes["DOCX"] = "DOCX";
    FileTypes["XLSX"] = "XLSX";
})(FileTypes || (exports.FileTypes = FileTypes = {}));
