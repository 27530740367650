"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CourseOptionLevel = exports.Currency = exports.AccessDurationType = exports.PackageStatus = exports.FormOfEducation = void 0;
var FormOfEducation;
(function (FormOfEducation) {
    FormOfEducation["FullTime"] = "FullTime";
    FormOfEducation["Distance"] = "Distance";
    FormOfEducation["External"] = "External";
    FormOfEducation["Individual"] = "Individual";
})(FormOfEducation || (exports.FormOfEducation = FormOfEducation = {}));
var PackageStatus;
(function (PackageStatus) {
    PackageStatus["Active"] = "Active";
    PackageStatus["Inactive"] = "Inactive";
})(PackageStatus || (exports.PackageStatus = PackageStatus = {}));
var AccessDurationType;
(function (AccessDurationType) {
    AccessDurationType["Fixed"] = "Fixed";
    AccessDurationType["Advanced"] = "Advanced";
})(AccessDurationType || (exports.AccessDurationType = AccessDurationType = {}));
var Currency;
(function (Currency) {
    Currency["UAH"] = "UAH";
    Currency["USD"] = "USD";
})(Currency || (exports.Currency = Currency = {}));
var CourseOptionLevel;
(function (CourseOptionLevel) {
    CourseOptionLevel["Basic"] = "Basic";
    CourseOptionLevel["Advanced"] = "Advanced";
})(CourseOptionLevel || (exports.CourseOptionLevel = CourseOptionLevel = {}));
