"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CourseFindResponseSchema = exports.CourseFindResponseItemSchema = exports.CourseFindParamsFormSchema = exports.CourseFindParamsSchema = exports.CourseFindExcludeParams = exports.CourseFindOrderParams = exports.CourseFindFilterParams = exports.CourseGetSchemaResponse = exports.CourseSchemaResponse = exports.CourseCreateUpdateFormSchema = exports.CourseCopyFormParamsSchema = exports.CourseCopyParamsSchema = exports.CourseUpdateStatusSchema = exports.CourseCreateUpdateSchema = exports.CourseParamsSchema = exports.AgeGroupDetachSchema = exports.AgeGroupAttachSchema = exports.AgeGroupsFindResponseSchema = exports.AgeGroupsFindParamsSchema = exports.AgeGroupsFindExcludeParams = exports.AgeGroupsFindOrderParams = exports.AgeGroupsFindFilterParamsSchema = exports.AgeGroupsSchemaResponse = exports.AgeGroupsParamsSchema = exports.AttachStudyPlansToAgeGroupSchema = exports.AgeGroupsCreateUpdateFormSchema = exports.AgeGroupsCreateUpdateSchema = exports.AgeGroupsSchema = exports.AgeGroupName = exports.CourseCategoriesFindResponseSchema = exports.CourseCategoriesFindParamsSchema = exports.CourseCategoriesFindOrderParams = exports.CourseCategoriesFindFilterParamsSchema = exports.CourseCategoriesSchemaResponse = exports.CourseCategoriesParamsSchema = exports.CourseCategoriesCreateUpdateSchema = exports.CourseCategoriesSchema = exports.CourseCategoryName = exports.TagsFindResponseSchema = exports.TagsFindParamsSchema = exports.TagsFindOrderParams = exports.TagsFindFilterParamsSchema = exports.TagsSchemaResponse = exports.TagsParamsSchema = exports.TagsCreateUpdateSchema = exports.EvaluationSystemSchema = exports.TagsSchema = exports.TagName = exports.CoursesSchema = exports.CourseNameSchema = void 0;
exports.AttachEnrollmentsFormSchema = exports.CourseLessonElementFinishSchema = exports.CourseLessonElementParamsSchema = exports.CourseLessonParamsSchema = exports.CourseStudentEjectSchema = exports.CourseStudentsEnrollmentsFindListResponseSchema = exports.CourseStudentsEnrollmentsFindResponseSchema = exports.CourseStudentsEnrollmentsFindParamsSchema = exports.CourseStudentsEnrollmentsFindOrderParamsSchema = exports.CourseStudentsEnrollmentsFindFilterParamsSchema = exports.CourseStudentResponseSchema = exports.CourseStudentResponseItemSchema = exports.CourseStudentSchema = exports.CourseStudentEnrollmentSchema = exports.CourseAvatarUrlResponseSchema = exports.CourseAvatarUrlPathSchema = exports.CourseAddUpdateAvatarSchema = exports.CourseItemsSchema = exports.CourseLessonsAndTestsItemSchema = exports.CourseVersionContentUpdateSchema = exports.CourseVersionAttachDetachSchema = exports.CourseVersionContentSchemaResponse = exports.CourseVersionTopicsWithLessonsSchemaResponse = exports.CourseVersionTopicLessonsTestsQuery = exports.CourseVersionFindResponseSchema = exports.CourseVersionWithStudyPlansSchemaResponse = exports.CourseVersionFindParamsSchema = exports.CourseCreateUpdateVersionFormSchema = exports.CourseVersionFindOrderParams = exports.CourseVersionFindFilterParamsSchema = exports.CourseVersionExtendedSchemaResponse = exports.CourseVersionSchemaResponse = exports.CourseVersionParamsSchema = exports.CourseVersionCreateUpdateSchema = exports.CourseVersionSchema = exports.CourseTagsSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
const common_1 = require("../../common");
const types_1 = require("./types");
const study_plan_1 = require("../study-plan");
const topic_1 = require("../topic");
const lesson_1 = require("../lesson");
const constants_1 = require("../../constants");
const school_year_1 = require("../school-year");
const constants_2 = require("./constants");
const user_1 = require("../user");
const test_1 = require("../test");
const course_evaluation_1 = require("../course-evaluation");
const grade_1 = require("../grade");
exports.CourseNameSchema = typebox_1.Type.String({ minLength: 1, maxLength: 200 });
exports.CoursesSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: exports.CourseNameSchema,
    short_name: typebox_1.Type.String({ maxLength: 15 }),
    image: (0, common_1.Nullable)(typebox_1.Type.String()),
    grade: (0, common_1.Nullable)(typebox_1.Type.Integer({ minimum: constants_2.GRADE_MIN_VALUE, maximum: constants_2.GRADE_MAX_VALUE })),
    languages: (0, common_1.Nullable)(typebox_1.Type.String()),
    requirement_notes: (0, common_1.Nullable)(typebox_1.Type.String({ maxLength: constants_1.POSTGRES_MAX_VARCHAR_SIZE })),
    description: (0, common_1.Nullable)(typebox_1.Type.String({ maxLength: constants_1.POSTGRES_MAX_VARCHAR_SIZE })),
    semesters_use: (0, common_1.Nullable)(typebox_1.Type.Boolean({ default: false })),
    status: typebox_1.Type.Enum(types_1.COURSE_STATUSES, { default: types_1.COURSE_STATUSES.ACTIVE }),
    evaluation_system: typebox_1.Type.Integer({ default: 1 }),
    created_by: typebox_1.Type.Integer(),
    updated_by: (0, common_1.Nullable)(typebox_1.Type.Integer()),
    avatar_url_path: (0, common_1.Nullable)(typebox_1.Type.String()),
}, { additionalProperties: false });
exports.TagName = typebox_1.Type.String({ minLength: 1, maxLength: 50 });
exports.TagsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: exports.TagName,
}, { additionalProperties: false });
exports.EvaluationSystemSchema = typebox_1.Type.Object({
    course_evaluation_system: course_evaluation_1.CourseEvaluationSchema,
});
exports.TagsCreateUpdateSchema = typebox_1.Type.Pick(exports.TagsSchema, ['name']);
exports.TagsParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.TagsSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.TagsSchema], {
    additionalProperties: false,
});
exports.TagsFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.TagsFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.TagsSchemaResponse, ['name']));
exports.TagsFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.TagsFindFilterParamsSchema,
    order: exports.TagsFindOrderParams,
});
exports.TagsFindResponseSchema = typebox_1.Type.Object({
    items: typebox_1.Type.Array(exports.TagsSchema),
    total: typebox_1.Type.Integer(),
});
exports.CourseCategoryName = typebox_1.Type.String({ minLength: 1, maxLength: 50 });
exports.CourseCategoriesSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: exports.CourseCategoryName,
}, { additionalProperties: false });
exports.CourseCategoriesCreateUpdateSchema = typebox_1.Type.Pick(exports.CourseCategoriesSchema, ['name']);
exports.CourseCategoriesParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.CourseCategoriesSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.CourseCategoriesSchema], {
    additionalProperties: false,
});
exports.CourseCategoriesFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.CourseCategoriesFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.CourseCategoriesSchemaResponse, ['name']));
exports.CourseCategoriesFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.CourseCategoriesFindFilterParamsSchema,
    order: exports.CourseCategoriesFindOrderParams,
});
exports.CourseCategoriesFindResponseSchema = typebox_1.Type.Object({
    items: typebox_1.Type.Array(exports.CourseCategoriesSchema),
    total: typebox_1.Type.Integer(),
});
exports.AgeGroupName = typebox_1.Type.String({ minLength: 1, maxLength: 50 });
exports.AgeGroupsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: exports.AgeGroupName,
    school_year_id: typebox_1.Type.Integer(),
    grade: typebox_1.Type.Integer({ minimum: constants_2.GRADE_MIN_VALUE, maximum: constants_2.GRADE_MAX_VALUE }),
    max_classroom_size: typebox_1.Type.Integer({ minimum: constants_2.MAX_CLASSROOM_SIZE_MIN_VALUE, default: user_1.DEFAULT_MAX_STUDENTS_COUNT }),
    assignment: typebox_1.Type.Enum(types_1.Assignment),
    study_plan_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
}, { additionalProperties: false });
exports.AgeGroupsCreateUpdateSchema = typebox_1.Type.Omit(exports.AgeGroupsSchema, ['id']);
exports.AgeGroupsCreateUpdateFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.AgeGroupsCreateUpdateSchema, ['school_year_id', 'study_plan_id', 'assignment', 'grade']),
    typebox_1.Type.Object({
        grade: common_1.FormLookupOption,
        school_year_id: common_1.FormLookupOption,
        study_plan_id: (0, typebox_1.Optional)(common_1.FormLookupOption),
        assignment: common_1.FormLookupOption,
    }),
]);
exports.AttachStudyPlansToAgeGroupSchema = typebox_1.Type.Object({
    study_plan_id: common_1.FormLookupOption,
});
exports.AgeGroupsParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.AgeGroupsSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.AgeGroupsSchema], {
    additionalProperties: false,
});
exports.AgeGroupsFindFilterParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    school_year_id: common_1.NaturalIntegerSchema,
}));
exports.AgeGroupsFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.AgeGroupsSchemaResponse, ['name']));
exports.AgeGroupsFindExcludeParams = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(common_1.NaturalIntegerSchema),
    grades: typebox_1.Type.Array(grade_1.GradeSchema),
});
exports.AgeGroupsFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.AgeGroupsFindFilterParamsSchema,
    order: exports.AgeGroupsFindOrderParams,
    exclude: exports.AgeGroupsFindExcludeParams,
});
exports.AgeGroupsFindResponseSchema = typebox_1.Type.Object({
    items: typebox_1.Type.Array(typebox_1.Type.Composite([
        exports.AgeGroupsSchema,
        typebox_1.Type.Pick(school_year_1.SchoolYearSchema, ['start', 'end']),
        typebox_1.Type.Object({
            study_plan_name: (0, common_1.Nullable)(study_plan_1.StudyPlanSchema.properties.name),
        }),
    ])),
    total: typebox_1.Type.Integer(),
});
exports.AgeGroupAttachSchema = typebox_1.Type.Object({
    age_group_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
    study_plan_id: typebox_1.Type.Integer(),
});
exports.AgeGroupDetachSchema = typebox_1.Type.Omit(exports.AgeGroupAttachSchema, ['study_plan_id']);
exports.CourseParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.CourseCreateUpdateSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.CoursesSchema, ['id', 'status', 'created_by', 'updated_by']),
    typebox_1.Type.Object({
        tag_ids: (0, common_1.Nullable)(typebox_1.Type.Array(typebox_1.Type.Integer())),
        category_ids: (0, common_1.Nullable)(typebox_1.Type.Array(typebox_1.Type.Integer())),
        status: typebox_1.Type.Optional(exports.CoursesSchema.properties.status),
    }),
], { additionalProperties: false });
exports.CourseUpdateStatusSchema = typebox_1.Type.Object({
    status: typebox_1.Type.Enum(types_1.COURSE_STATUSES),
}, { additionalProperties: false });
exports.CourseCopyParamsSchema = typebox_1.Type.Object({
    name: exports.CourseNameSchema,
});
exports.CourseCopyFormParamsSchema = typebox_1.Type.Object({
    oldName: exports.CourseNameSchema,
    name: exports.CourseNameSchema,
});
exports.CourseCreateUpdateFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.CourseCreateUpdateSchema, ['tag_ids', 'category_ids', 'status', 'languages', 'grade', 'evaluation_system']),
    typebox_1.Type.Object({
        tag_ids: typebox_1.Type.Union([typebox_1.Type.Array(common_1.FormLookupOption), typebox_1.Type.Null()]),
        category_ids: typebox_1.Type.Union([typebox_1.Type.Array(common_1.FormLookupOption), typebox_1.Type.Null()]),
        grade: typebox_1.Type.Optional(common_1.FormLookupOption),
        status: typebox_1.Type.Optional(common_1.FormLookupOption),
        languages: typebox_1.Type.Optional(common_1.FormLookupOption),
        evaluation_system: common_1.FormLookupOption,
    }),
]);
exports.CourseSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.CoursesSchema, exports.EvaluationSystemSchema], {
    additionalProperties: false,
});
exports.CourseGetSchemaResponse = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.CourseSchemaResponse, ['evaluation_system', 'tag_ids', 'category_ids']),
    typebox_1.Type.Object({
        tags: typebox_1.Type.Optional(typebox_1.Type.Array(exports.TagsSchema)),
        grade: (0, common_1.Nullable)(typebox_1.Type.Integer()),
        categories: typebox_1.Type.Optional(typebox_1.Type.Array(exports.CourseCategoriesSchema)),
    }),
], {
    additionalProperties: false,
});
exports.CourseFindFilterParams = typebox_1.Type.Partial(typebox_1.Type.Object({
    name: (0, common_1.Nullable)(typebox_1.Type.String()),
    categories: (0, common_1.Nullable)(typebox_1.Type.Array(typebox_1.Type.Integer())),
    grade: (0, common_1.Nullable)(typebox_1.Type.Integer()),
    status: typebox_1.Type.Enum(types_1.COURSE_STATUSES),
    languages: (0, common_1.Nullable)(typebox_1.Type.String()),
    study_plan_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
    study_plan_relation: (0, common_1.Nullable)(typebox_1.Type.Enum(study_plan_1.STUDY_PLAN_RELATION, { default: study_plan_1.STUDY_PLAN_RELATION.ALL })),
}));
exports.CourseFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Composite([
    typebox_1.Type.Pick(exports.CourseSchemaResponse, ['name']),
    typebox_1.Type.Object({ grade: typebox_1.Type.Integer() }),
]));
exports.CourseFindExcludeParams = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(common_1.NaturalIntegerSchema),
});
exports.CourseFindParamsSchema = (0, responses_1.FindParams)({
    filters: typebox_1.Type.Partial(exports.CourseFindFilterParams),
    exclude: typebox_1.Type.Partial(exports.CourseFindExcludeParams),
    order: exports.CourseFindOrderParams,
});
exports.CourseFindParamsFormSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    categories: typebox_1.Type.Array(common_1.FormLookupOption),
    grade: common_1.FormLookupOption,
    status: common_1.FormLookupOption,
    languages: common_1.FormLookupOption,
    study_plan_id: common_1.FormLookupOption,
    study_plan_relation: typebox_1.Type.Optional((0, common_1.Nullable)(typebox_1.Type.Enum(study_plan_1.STUDY_PLAN_RELATION))),
}));
exports.CourseFindResponseItemSchema = typebox_1.Type.Composite([
    exports.CourseSchemaResponse,
    typebox_1.Type.Object({
        categories: typebox_1.Type.Array(exports.CourseCategoriesSchema),
        grade: (0, common_1.Nullable)(typebox_1.Type.Integer()),
        is_in_study_plan: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
        version_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
        version_name: (0, common_1.Nullable)(typebox_1.Type.String()),
    }),
]);
exports.CourseFindResponseSchema = typebox_1.Type.Object({
    items: typebox_1.Type.Array(exports.CourseFindResponseItemSchema),
    total: typebox_1.Type.Integer(),
});
exports.CourseTagsSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Integer(),
    tag_id: typebox_1.Type.Integer(),
});
exports.CourseVersionSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    course_id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String({ minLength: 1, maxLength: 50 }),
    notes: (0, common_1.Nullable)(typebox_1.Type.String({ maxLength: 300 })),
    status: typebox_1.Type.Enum(types_1.COURSE_STATUSES, { default: types_1.COURSE_STATUSES.ACTIVE }),
    created_by: typebox_1.Type.Integer(),
    updated_by: (0, common_1.Nullable)(typebox_1.Type.Integer()),
    created_by_ip: (0, common_1.Nullable)(typebox_1.Type.String()),
    updated_by_ip: (0, common_1.Nullable)(typebox_1.Type.String()),
}, { additionalProperties: false });
exports.CourseVersionCreateUpdateSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.CourseVersionSchema, [
        'id',
        'course_id',
        'status',
        'created_by',
        'updated_by',
        'created_by_ip',
        'updated_by_ip',
    ]),
    typebox_1.Type.Object({
        status: typebox_1.Type.Optional(exports.CourseVersionSchema.properties.status),
    }),
]);
exports.CourseVersionParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    versionId: typebox_1.Type.Integer(),
});
exports.CourseVersionSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.CourseVersionSchema], {
    additionalProperties: false,
});
exports.CourseVersionExtendedSchemaResponse = typebox_1.Type.Composite([
    exports.CourseVersionSchemaResponse,
    typebox_1.Type.Object({
        creator: typebox_1.Type.Object({
            first_name: typebox_1.Type.String(),
            last_name: typebox_1.Type.String(),
            middle_name: (0, common_1.Nullable)(typebox_1.Type.String()),
        }),
        modifier: (0, common_1.Nullable)(typebox_1.Type.Object({
            first_name: typebox_1.Type.String(),
            last_name: typebox_1.Type.String(),
            middle_name: (0, common_1.Nullable)(typebox_1.Type.String()),
        })),
    }),
]);
exports.CourseVersionFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.CourseVersionFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.CourseVersionSchemaResponse, [
    'created_at',
    'updated_at',
    'name',
]));
exports.CourseCreateUpdateVersionFormSchema = typebox_1.Type.Object({
    name: typebox_1.Type.String({ minLength: 1, maxLength: 50 }),
    notes: (0, common_1.Nullable)(typebox_1.Type.String({ maxLength: 300 })),
    status: typebox_1.Type.Union([common_1.FormLookupOption, typebox_1.Type.Null()]),
});
exports.CourseVersionFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.CourseVersionFindFilterParamsSchema,
    order: exports.CourseVersionFindOrderParams,
});
exports.CourseVersionWithStudyPlansSchemaResponse = typebox_1.Type.Composite([
    exports.CourseVersionSchemaResponse,
    typebox_1.Type.Object({
        study_plans: typebox_1.Type.Array(typebox_1.Type.Object({
            id: typebox_1.Type.Integer(),
            name: typebox_1.Type.String(),
        })),
    }),
]);
exports.CourseVersionFindResponseSchema = typebox_1.Type.Object({
    items: typebox_1.Type.Array(exports.CourseVersionWithStudyPlansSchemaResponse),
    total: typebox_1.Type.Integer(),
});
exports.CourseVersionTopicLessonsTestsQuery = typebox_1.Type.Array(typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    name: typebox_1.Type.String(),
    semester: typebox_1.Type.Enum(topic_1.SEMESTER),
    required: typebox_1.Type.Boolean(),
    order: typebox_1.Type.Number(),
    lessons: typebox_1.Type.Array(typebox_1.Type.Object({
        id: typebox_1.Type.Number(),
        name: typebox_1.Type.String(),
        order: typebox_1.Type.Number(),
        status: typebox_1.Type.Enum(types_1.COURSE_STATUSES),
        type: typebox_1.Type.Literal(constants_2.COURSE_CONTENT_TYPES.Lesson),
    })),
    tests: typebox_1.Type.Array(typebox_1.Type.Object({
        id: typebox_1.Type.Number(),
        name: typebox_1.Type.String(),
        order: typebox_1.Type.Number(),
        status: typebox_1.Type.Null(),
        type: typebox_1.Type.Literal(constants_2.COURSE_CONTENT_TYPES.Test),
    })),
}));
exports.CourseVersionTopicsWithLessonsSchemaResponse = typebox_1.Type.Array(typebox_1.Type.Composite([
    typebox_1.Type.Pick(topic_1.TopicSchema, ['id', 'name', 'semester', 'required', 'order']),
    typebox_1.Type.Object({
        items: typebox_1.Type.Array(typebox_1.Type.Union([
            typebox_1.Type.Composite([
                typebox_1.Type.Object({
                    type: typebox_1.Type.Literal(constants_2.COURSE_CONTENT_TYPES.Lesson),
                }),
                typebox_1.Type.Pick(lesson_1.LessonSchema, ['id', 'name', 'status']),
                typebox_1.Type.Pick(lesson_1.LessonSingleAttachSchema, ['order']),
            ]),
            typebox_1.Type.Composite([
                typebox_1.Type.Pick(test_1.TestEntitySchema, ['id', 'name']),
                typebox_1.Type.Object({
                    status: typebox_1.Type.Null(),
                    order: typebox_1.Type.Integer(),
                    type: typebox_1.Type.Literal(constants_2.COURSE_CONTENT_TYPES.Test),
                }),
            ]),
        ])),
    }),
]));
exports.CourseVersionContentSchemaResponse = typebox_1.Type.Object({
    content: exports.CourseVersionTopicsWithLessonsSchemaResponse,
});
exports.CourseVersionAttachDetachSchema = typebox_1.Type.Object({
    study_plan_id: typebox_1.Type.Integer(),
    version_ids: typebox_1.Type.Array(typebox_1.Type.Integer(), { maxItems: constants_2.MAX_COURSE_IN_STUDY_PLAN }),
});
exports.CourseVersionContentUpdateSchema = typebox_1.Type.Object({
    content: typebox_1.Type.Array(typebox_1.Type.Composite([
        typebox_1.Type.Pick(topic_1.TopicSchema, ['id', 'order', 'semester']),
        typebox_1.Type.Object({
            items: typebox_1.Type.Array(typebox_1.Type.Union([
                typebox_1.Type.Composite([
                    typebox_1.Type.Object({
                        type: typebox_1.Type.Literal(constants_2.COURSE_CONTENT_TYPES.Lesson),
                    }),
                    typebox_1.Type.Pick(lesson_1.LessonSchema, ['id']),
                    typebox_1.Type.Pick(lesson_1.LessonSingleAttachSchema, ['order']),
                ]),
                typebox_1.Type.Composite([
                    typebox_1.Type.Pick(test_1.TestEntitySchema, ['id']),
                    typebox_1.Type.Object({
                        order: typebox_1.Type.Integer(),
                        type: typebox_1.Type.Literal(constants_2.COURSE_CONTENT_TYPES.Test),
                    }),
                ]),
            ])),
        }),
    ])),
});
exports.CourseLessonsAndTestsItemSchema = typebox_1.Type.Object({
    lesson_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
    test_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
});
exports.CourseItemsSchema = typebox_1.Type.Object({
    lessons: typebox_1.Type.Array(typebox_1.Type.Integer()),
    tests: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.CourseAddUpdateAvatarSchema = typebox_1.Type.Object({
    avatar: common_1.AvatarFileSchema,
});
exports.CourseAvatarUrlPathSchema = typebox_1.Type.Object({
    avatar_url_path: (0, common_1.Nullable)(typebox_1.Type.String()),
});
exports.CourseAvatarUrlResponseSchema = exports.CourseAvatarUrlPathSchema;
exports.CourseStudentEnrollmentSchema = typebox_1.Type.Object({
    users_ids: typebox_1.Type.Array(typebox_1.Type.Integer(), { minItems: 1 }),
    course_version_id: typebox_1.Type.Integer(),
    period_start: typebox_1.Type.String({ format: 'date-time' }),
    period_end: typebox_1.Type.String({ format: 'date-time' }),
});
exports.CourseStudentSchema = typebox_1.Type.Object({
    user_id: typebox_1.Type.Integer(),
    course_id: typebox_1.Type.Integer(),
    course_version_name: typebox_1.Type.String(),
    course_version_id: typebox_1.Type.Integer(),
    period_start: typebox_1.Type.String({ format: 'date-time' }),
    period_end: typebox_1.Type.String({ format: 'date-time' }),
});
exports.CourseStudentResponseItemSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    first_name: typebox_1.Type.String(user_1.FirstNameSchema),
    last_name: typebox_1.Type.String(user_1.LastNameSchema),
});
exports.CourseStudentResponseSchema = typebox_1.Type.Object({
    success: typebox_1.Type.Array(exports.CourseStudentResponseItemSchema),
    errors: typebox_1.Type.Array(exports.CourseStudentResponseItemSchema),
});
exports.CourseStudentsEnrollmentsFindFilterParamsSchema = typebox_1.Type.Object({
    first_name: typebox_1.Type.Optional(user_1.FirstNameSchema),
    middle_name: typebox_1.Type.Optional(user_1.MiddleNameSchema),
    last_name: typebox_1.Type.Optional(user_1.LastNameSchema),
    code: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    email: typebox_1.Type.Optional(common_1.WeakEmailSchema),
});
exports.CourseStudentsEnrollmentsFindOrderParamsSchema = typebox_1.Type.Pick(exports.CourseStudentsEnrollmentsFindFilterParamsSchema, ['first_name', 'last_name', 'middle_name', 'code']);
exports.CourseStudentsEnrollmentsFindParamsSchema = (0, responses_1.FindParams)({
    filters: typebox_1.Type.Partial(exports.CourseStudentsEnrollmentsFindFilterParamsSchema),
    order: typebox_1.Type.Partial(exports.CourseStudentsEnrollmentsFindOrderParamsSchema),
});
exports.CourseStudentsEnrollmentsFindResponseSchema = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.Integer(),
        first_name: user_1.FirstNameSchema,
        middle_name: user_1.MiddleNameSchema,
        last_name: user_1.LastNameSchema,
        code: typebox_1.Type.Integer(),
        email: common_1.EmailSchema,
    }),
    typebox_1.Type.Omit(exports.CourseStudentSchema, ['user_id']),
]);
exports.CourseStudentsEnrollmentsFindListResponseSchema = (0, responses_1.PaginatedList)(exports.CourseStudentsEnrollmentsFindResponseSchema);
exports.CourseStudentEjectSchema = typebox_1.Type.Pick(exports.CourseStudentEnrollmentSchema, ['users_ids']);
exports.CourseLessonParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    course_version_id: typebox_1.Type.Integer(),
    lesson_id: typebox_1.Type.Integer(),
});
exports.CourseLessonElementParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    course_version_id: typebox_1.Type.Integer(),
    lesson_id: typebox_1.Type.Integer(),
    element_id: typebox_1.Type.Integer(),
});
exports.CourseLessonElementFinishSchema = typebox_1.Type.Object({
    metadata: typebox_1.Type.Recursive((Self) => typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Union([typebox_1.Type.String(), Self]))),
});
exports.AttachEnrollmentsFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        course_version: common_1.FormLookupOption,
        start: typebox_1.Type.String(),
        end: typebox_1.Type.String(),
    }),
]);
