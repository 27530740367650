"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDocumentStatus = exports.UserDocumentSubType = exports.UserDocumentType = exports.UserStatus = exports.SystemRole = exports.ContactPriority = exports.DeliveryService = exports.DeliveryObtainType = exports.AddressType = exports.Sex = void 0;
var Sex;
(function (Sex) {
    Sex["FEMALE"] = "0";
    Sex["MALE"] = "1";
})(Sex || (exports.Sex = Sex = {}));
var AddressType;
(function (AddressType) {
    AddressType["REGISTRATION"] = "0";
    AddressType["DELIVERY"] = "1";
})(AddressType || (exports.AddressType = AddressType = {}));
var DeliveryObtainType;
(function (DeliveryObtainType) {
    DeliveryObtainType["DEPARTMENTS"] = "0";
    DeliveryObtainType["POST"] = "1";
})(DeliveryObtainType || (exports.DeliveryObtainType = DeliveryObtainType = {}));
var DeliveryService;
(function (DeliveryService) {
    DeliveryService["NEW_POST"] = "0";
})(DeliveryService || (exports.DeliveryService = DeliveryService = {}));
var ContactPriority;
(function (ContactPriority) {
    ContactPriority["PHONE"] = "0";
    ContactPriority["EMAIL"] = "1";
})(ContactPriority || (exports.ContactPriority = ContactPriority = {}));
var SystemRole;
(function (SystemRole) {
    SystemRole["Administrator"] = "Administrator";
    SystemRole["Student"] = "Student";
    SystemRole["Teacher"] = "Teacher";
    SystemRole["Mentor"] = "Mentor";
    SystemRole["Parent"] = "Parent";
    SystemRole["Marketer"] = "Marketer";
    SystemRole["SeniorTeacher"] = "SeniorTeacher";
})(SystemRole || (exports.SystemRole = SystemRole = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "Active";
    UserStatus["Blocked"] = "Blocked";
    UserStatus["Invited"] = "Invited";
})(UserStatus || (exports.UserStatus = UserStatus = {}));
var UserDocumentType;
(function (UserDocumentType) {
    UserDocumentType["StudentEnrollment"] = "StudentEnrollment";
    UserDocumentType["ExternEnrollment"] = "ExternEnrollment";
    UserDocumentType["StudentPublicOfferAgreement"] = "StudentPublicOfferAgreement";
    UserDocumentType["ExternPublicOfferAgreement"] = "ExternPublicOfferAgreement";
    UserDocumentType["ParentIdentificationDocument"] = "ParentIdentificationDocument";
    UserDocumentType["ChildIdentificationDocument"] = "ChildIdentificationDocument";
    UserDocumentType["AdultIdentificationDocument"] = "AdultIdentificationDocument";
    UserDocumentType["Other"] = "Other";
})(UserDocumentType || (exports.UserDocumentType = UserDocumentType = {}));
var UserDocumentSubType;
(function (UserDocumentSubType) {
    UserDocumentSubType["Passport"] = "Passport";
    UserDocumentSubType["BirthCertificate"] = "BirthCertificate";
})(UserDocumentSubType || (exports.UserDocumentSubType = UserDocumentSubType = {}));
var UserDocumentStatus;
(function (UserDocumentStatus) {
    UserDocumentStatus["Empty"] = "Empty";
    UserDocumentStatus["Uploaded"] = "Uploaded";
    UserDocumentStatus["OnReview"] = "OnReview";
    UserDocumentStatus["Rejected"] = "Rejected";
    UserDocumentStatus["Approved"] = "Approved";
})(UserDocumentStatus || (exports.UserDocumentStatus = UserDocumentStatus = {}));
