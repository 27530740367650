"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeworkJobParamsSchema = exports.HomeworkParamsSchema = exports.HomeworkJobSubmitPayload = exports.HomeworkJobResponseSchema = exports.HomeworkJobEssaySchema = exports.HomeworkJobFileUploadSchema = exports.HomeworkEssaySchema = exports.HomeworkFileUploadSchema = exports.HomeworkRawSchema = exports.HomeworkJobEssayRawSchema = exports.HomeworkJobFileUploadRawSchema = exports.HomeworkJobBaseSchema = exports.HomeworkJobsFindParamsSchema = exports.HomeworkJobsFindFilterSchema = exports.HomeworkJobsFindListResponseSchema = exports.HomeworkJobsFindResponseSchema = exports.HomeworkJobsFindSchema = exports.HomeworkTestsFindListResponseSchema = exports.HomeworkTestsFindResponseSchema = exports.HomeworkTestsFindParamsSchema = exports.HomeworkTestsFindFilterSchema = exports.HomeworkTestsFindSchema = exports.HomeworkUserDataSchema = exports.HomeworkUserFullnameSchema = exports.HomeworkCoursesFindListResponseSchema = exports.HomeworkCoursesFindResponseSchema = exports.HomeworkCoursesFindParamsSchema = exports.HomeworkCoursesFindFilterSchema = exports.HomeworkCoursesFindSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const course_1 = require("../course");
const test_1 = require("../test");
const user_1 = require("../user");
const common_1 = require("../../common");
const question_1 = require("../question");
const utils_1 = require("../../utils");
const question_answer_1 = require("../question-answer");
const responses_1 = require("../../responses");
exports.HomeworkCoursesFindSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Integer(),
    course_name: typebox_1.Type.String(),
    test_name: test_1.TestNameBaseSchema,
    lesson_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
    topic_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.HomeworkCoursesFindFilterSchema = typebox_1.Type.Partial(exports.HomeworkCoursesFindSchema);
exports.HomeworkCoursesFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.HomeworkCoursesFindFilterSchema,
});
exports.HomeworkCoursesFindResponseSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Integer(),
    course_name: course_1.CourseNameSchema,
    count_to_review: typebox_1.Type.Integer(),
    last_submitted_task_to_review: typebox_1.Type.String({
        format: 'date-time',
    }),
});
exports.HomeworkCoursesFindListResponseSchema = (0, responses_1.PaginatedList)(exports.HomeworkCoursesFindResponseSchema);
exports.HomeworkUserFullnameSchema = typebox_1.Type.Object({
    first_name: user_1.FirstNameSchema,
    last_name: user_1.LastNameSchema,
    middle_name: user_1.MiddleNameSchema,
});
exports.HomeworkUserDataSchema = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.Integer(),
    }),
    exports.HomeworkUserFullnameSchema,
]);
exports.HomeworkTestsFindSchema = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        test_name: test_1.TestNameBaseSchema,
        course_id: common_1.NaturalIntegerSchema,
    }),
    exports.HomeworkUserFullnameSchema,
]);
exports.HomeworkTestsFindFilterSchema = typebox_1.Type.Partial(exports.HomeworkTestsFindSchema);
exports.HomeworkTestsFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.HomeworkTestsFindFilterSchema,
});
exports.HomeworkTestsFindResponseSchema = typebox_1.Type.Object({
    jobs_to_review: typebox_1.Type.Integer(),
    test_id: typebox_1.Type.Integer(),
    test_name: test_1.TestNameBaseSchema,
    last_submitted_task_to_review: typebox_1.Type.String({
        format: 'date-time',
    }),
    users: typebox_1.Type.Array(exports.HomeworkUserDataSchema),
});
exports.HomeworkTestsFindListResponseSchema = (0, responses_1.PaginatedList)(exports.HomeworkTestsFindResponseSchema);
exports.HomeworkJobsFindSchema = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        test_id: common_1.NaturalIntegerSchema,
        created_at: typebox_1.Type.String({
            format: 'date-time',
        }),
        status: typebox_1.Type.Boolean(),
        score: (0, common_1.Nullable)(test_1.ScoreSchema),
    }),
    exports.HomeworkUserFullnameSchema,
]);
exports.HomeworkJobsFindResponseSchema = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        job_id: typebox_1.Type.Integer(),
        reviewed_at: (0, common_1.Nullable)(typebox_1.Type.String({
            format: 'date-time',
        })),
    }),
    typebox_1.Type.Omit(exports.HomeworkJobsFindSchema, ['status', 'test_id']),
]);
exports.HomeworkJobsFindListResponseSchema = (0, responses_1.PaginatedList)(exports.HomeworkJobsFindResponseSchema);
exports.HomeworkJobsFindFilterSchema = typebox_1.Type.Partial(exports.HomeworkJobsFindSchema);
exports.HomeworkJobsFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.HomeworkJobsFindFilterSchema,
});
exports.HomeworkJobBaseSchema = typebox_1.Type.Object({
    question_name: question_1.QuestionNameSchema,
    question_id: typebox_1.Type.Integer(),
    comment: (0, common_1.Nullable)(typebox_1.Type.String()),
    score: (0, common_1.Nullable)(question_1.QuestionUnsignedPointsSchema),
});
exports.HomeworkJobFileUploadRawSchema = typebox_1.Type.Composite([exports.HomeworkJobBaseSchema, question_answer_1.QuestionAnswerFileUploadSchema]);
exports.HomeworkJobEssayRawSchema = typebox_1.Type.Composite([exports.HomeworkJobBaseSchema, question_answer_1.QuestionAnswerEssaySchema]);
exports.HomeworkRawSchema = (0, utils_1.OneOf)([exports.HomeworkJobFileUploadRawSchema, exports.HomeworkJobEssayRawSchema], {
    type: 'object',
    discriminator: { propertyName: 'response_type' },
    required: ['response_type'],
});
exports.HomeworkFileUploadSchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.FileUpload),
    files_urls: (0, common_1.Nullable)(typebox_1.Type.Array(typebox_1.Type.String())),
    content: typebox_1.Type.Null(),
});
exports.HomeworkEssaySchema = typebox_1.Type.Object({
    response_type: typebox_1.Type.Literal(question_1.QuestionType.Essay),
    files_urls: typebox_1.Type.Null(),
    content: (0, common_1.Nullable)(typebox_1.Type.String()),
});
exports.HomeworkJobFileUploadSchema = typebox_1.Type.Composite([exports.HomeworkJobBaseSchema, exports.HomeworkFileUploadSchema], {
    additionalProperties: false,
});
exports.HomeworkJobEssaySchema = typebox_1.Type.Composite([exports.HomeworkJobBaseSchema, exports.HomeworkEssaySchema], {
    additionalProperties: false,
});
exports.HomeworkJobResponseSchema = (0, utils_1.OneOf)([exports.HomeworkJobFileUploadSchema, exports.HomeworkJobEssaySchema], {
    type: 'object',
    discriminator: { propertyName: 'response_type' },
    required: ['response_type'],
});
exports.HomeworkJobSubmitPayload = typebox_1.Type.Object({
    comment: (0, common_1.Nullable)(typebox_1.Type.String()),
    score: question_1.QuestionUnsignedPointsSchema,
    answer_type: typebox_1.Type.Enum(question_answer_1.AnswerType),
});
exports.HomeworkParamsSchema = typebox_1.Type.Object({
    homework_id: typebox_1.Type.Integer(),
});
exports.HomeworkJobParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
