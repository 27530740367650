"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CourseEvaluationListSchema = exports.CourseEvaluationSchema = exports.CourseEvaluationBaseSchema = exports.CourseEvaluationSystemParamsSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("./types");
const baseEntity_1 = require("../baseEntity");
exports.CourseEvaluationSystemParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
});
exports.CourseEvaluationBaseSchema = typebox_1.Type.Object({
    name: typebox_1.Type.String({ maxLength: 255 }),
    maximum_degree: typebox_1.Type.Enum(types_1.CourseEvaluationSystem),
});
exports.CourseEvaluationSchema = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.CourseEvaluationBaseSchema]);
exports.CourseEvaluationListSchema = typebox_1.Type.Array(exports.CourseEvaluationSchema);
