"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageSpecializationFindResponseSchema = exports.PackageSpecializationFindParamsSchema = exports.PackageSpecializationOrderParamsSchema = exports.PackageSpecializationFilterParams = exports.PackageSpecializationResponse = exports.PackageSpecializationParamsSchema = exports.PackageSpecializationCreateUpdatePayload = exports.PackageSpecializationSchema = exports.PackageSpecializationBaseSchema = exports.PackageSpecializationNameSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
exports.PackageSpecializationNameSchema = typebox_1.Type.String({ minLength: 1, maxLength: 100 });
exports.PackageSpecializationBaseSchema = typebox_1.Type.Object({
    name: exports.PackageSpecializationNameSchema,
});
exports.PackageSpecializationSchema = typebox_1.Type.Composite([exports.PackageSpecializationBaseSchema, baseEntity_1.BaseEntitySchema]);
exports.PackageSpecializationCreateUpdatePayload = exports.PackageSpecializationBaseSchema;
exports.PackageSpecializationParamsSchema = typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['id']);
exports.PackageSpecializationResponse = typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['id']);
exports.PackageSpecializationFilterParams = typebox_1.Type.Partial(exports.PackageSpecializationBaseSchema);
exports.PackageSpecializationOrderParamsSchema = typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['created_at']);
exports.PackageSpecializationFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.PackageSpecializationFilterParams,
    order: exports.PackageSpecializationOrderParamsSchema,
});
exports.PackageSpecializationFindResponseSchema = (0, responses_1.PaginatedList)(exports.PackageSpecializationSchema);
