"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnswerType = void 0;
var AnswerType;
(function (AnswerType) {
    AnswerType["Correct"] = "Correct";
    AnswerType["PartialyCorrect"] = "PartialyCorrect";
    AnswerType["Incorrect"] = "Incorrect";
    AnswerType["Processing"] = "Processing";
})(AnswerType || (exports.AnswerType = AnswerType = {}));
