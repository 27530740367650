"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompletionCriteriaPayloadSchema = exports.CompletionCriteriaOneOfBaseSchema = exports.CompletionCriteriaItemsResponseSchema = exports.CompletionCriteriaItemSchema = exports.CompletionCriteriaItemBaseSchema = exports.CompletionCriteriaItemsPayloadSchema = exports.CompletionCriteriaParamsSchema = exports.CompletionCriteriaSchema = exports.CompletionCriteriaBaseSchema = exports.PercentageSchema = exports.MinScoreSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("./types");
const baseEntity_1 = require("../baseEntity");
const common_1 = require("../../common");
const constants_1 = require("./constants");
const utils_1 = require("../../utils");
exports.MinScoreSchema = typebox_1.Type.Number({
    minimum: constants_1.COMPLETION_CRITERIA_SCORE_MIN_VALUE,
    maximum: constants_1.COMPLETION_CRITERIA_SCORE_MAX_VALUE,
    multipleOf: 0.01,
});
exports.PercentageSchema = typebox_1.Type.Number({
    minimum: constants_1.COMPLETION_CRITERIA_PERCENTAGE_MIN_VALUE,
    maximum: constants_1.COMPLETION_CRITERIA_PERCENTAGE_MAX_VALUE,
    multipleOf: 0.01,
    default: constants_1.COMPLETION_CRITERIA_PERCENTAGE_DEFAULT_VALUE,
});
exports.CompletionCriteriaBaseSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Integer(),
    type: typebox_1.Type.Enum(types_1.CompletionCriteria, { default: types_1.CompletionCriteria.Points }),
    min_score: (0, common_1.Nullable)(exports.MinScoreSchema),
    completion_percentage: (0, common_1.Nullable)(exports.PercentageSchema),
    project_defense_required: typebox_1.Type.Boolean({ default: false }),
});
exports.CompletionCriteriaSchema = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.CompletionCriteriaBaseSchema]);
exports.CompletionCriteriaParamsSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Integer(),
});
exports.CompletionCriteriaItemsPayloadSchema = typebox_1.Type.Object({
    lessons: typebox_1.Type.Array(typebox_1.Type.Integer()),
    tests: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.CompletionCriteriaItemBaseSchema = typebox_1.Type.Object({
    criteria_id: typebox_1.Type.Integer(),
    lesson_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
    test_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
});
exports.CompletionCriteriaItemSchema = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.Integer(),
    }),
    exports.CompletionCriteriaItemBaseSchema,
]);
exports.CompletionCriteriaItemsResponseSchema = exports.CompletionCriteriaItemsPayloadSchema;
exports.CompletionCriteriaOneOfBaseSchema = typebox_1.Type.Pick(exports.CompletionCriteriaBaseSchema, [
    'course_id',
    'project_defense_required',
]);
exports.CompletionCriteriaPayloadSchema = (0, utils_1.OneOf)([
    typebox_1.Type.Composite([
        exports.CompletionCriteriaOneOfBaseSchema,
        typebox_1.Type.Object({
            type: typebox_1.Type.Literal(types_1.CompletionCriteria.Points),
            min_score: (0, common_1.Nullable)(exports.MinScoreSchema),
            completion_percentage: (0, common_1.Nullable)(exports.PercentageSchema),
        }, {
            additionalProperties: false,
        }),
    ]),
    typebox_1.Type.Composite([
        exports.CompletionCriteriaOneOfBaseSchema,
        typebox_1.Type.Object({
            type: typebox_1.Type.Literal(types_1.CompletionCriteria.AverageGrade),
            min_score: (0, common_1.Nullable)(exports.MinScoreSchema),
            completion_percentage: (0, common_1.Nullable)(exports.PercentageSchema),
        }, { additionalProperties: false }),
    ]),
    typebox_1.Type.Composite([
        exports.CompletionCriteriaOneOfBaseSchema,
        typebox_1.Type.Object({
            type: typebox_1.Type.Literal(types_1.CompletionCriteria.LessonCompletion),
            min_score: typebox_1.Type.Null(),
            completion_percentage: (0, common_1.Nullable)(exports.PercentageSchema),
        }, { additionalProperties: false }),
    ]),
    typebox_1.Type.Composite([
        exports.CompletionCriteriaOneOfBaseSchema,
        typebox_1.Type.Object({
            type: typebox_1.Type.Literal(types_1.CompletionCriteria.TaskCompletion),
            min_score: typebox_1.Type.Null(),
            completion_percentage: (0, common_1.Nullable)(exports.PercentageSchema),
        }, { additionalProperties: false }),
    ]),
], {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
